<template>
  <section
    id="segment"
    class="pb-0"
  >
    <div class="container">
      <div class="row mb-50">
        <div class="col-lg-6">
          <h2 class="wow fadeInUp">
            {{ lang === 'ru' ? 'Наши грузы' : 'Our cargo' }}
          </h2>
          <!-- <p class="big-white wow fadeInUp">
            Таможенное оформление грузов происходит исключительно при взаимодействии с таможенными органами и заблаговременном согласовании всех нюансов.
          </p> -->
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 text-center wow fadeInUp"
          data-wow-delay=".2s"
          v-for="(item, i) in icons"
          :key="i"
        >
          <div class="icon-box">
            <img
              :src="item.img"
              alt=""
            >
            <h4 v-html="item[lang]" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const basicScroll = require('basicscroll')
import { mapGetters } from 'vuex';

export default {
  computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return {
            icons: [
                {
                    img: require('../assets/img/si1.svg'),
                    ru: 'Автоматика',
                    en: 'Automation'
                },
                {
                    img: require('../assets/img/si2.svg'),
                    ru: 'Оборудование',
                    en: 'Equipment'
                },
                {
                    img: require('../assets/img/si3.svg'),
                    ru: 'Инструмент',
                    en: 'Tools'
                },
                {
                    img: require('../assets/img/si4.svg'),
                    ru: 'Медицинские <br> изделия',
                    en: 'Medical<br>products'
                },
                {
                    img: require('../assets/img/si6.svg'),
                    ru: 'Электронные <br> компоненты',
                    en: 'Electronic<br> components'
                },
                {
                    img: require('../assets/img/si7.svg'),
                    ru: 'Одежда и обувь',
                    en: 'Clothing & Shoes'
                },
                {
                    img: require('../assets/img/si5.svg'),
                    ru: 'Запчасти',
                    en: 'Spare<br> parts'
                },
                {
                    img: require('../assets/img/si8.svg'),
                    ru: 'Мебель',
                    en: 'Furniture'
                }
            ]
        }
    }
}
</script>

<style scoped>
h2{
    padding-left: 50px;
}
h2:before{
    left: 0px !important;
    opacity: .3;
}
</style>