<template>
  <div
    class="pop"
    @click="clearSingle"
  >
    <div
      class="close-btn"
      @click="clearSingle"
    >
      <span class="mdi mdi-close" />
    </div>
    <div
      class="pop-box"
      @click.stop
    >
      <div class="row">
        <div class="col-lg-6">
          <h3 class="dark-h3 mb-30">
            Тарифные меры
          </h3>
        </div>
        <div class="col-lg-6 text-right">
          <h3 class="dark-h3">
            Код: <span class="orange">{{ selectedCode }}</span>
          </h3>
        </div>
      </div>
            
      <!-- <pre>{{singleCode}}</pre> -->
      <div
        v-for="(box, i) in Object.keys(singleCode.commonTarif)"
        :key="i"
      >
        <div
          class="mini-grey-box mb-15"
          v-if="singleCode.commonTarif[box].length"
        >
          <h4>{{ singleCode.commonTarif[box][0].nsiTarifList.paymentTypeDetails }}</h4>
          <div class="row">
            <div class="col-lg-8">
              <p class="black-txt">
                {{ singleCode.commonTarif[box][0].nsiTarifList.longDesc }}
              </p>
            </div>
            <div class="col-lg-4">
              <p v-if="singleCode.commonTarif[box][0].rate.includes('BYN')">
                <strong>{{ (Math.round(parseInt(singleCode.commonTarif[box][0].rate) * BYNCource).toLocaleString()) }} RUB</strong>
              </p>
              <p
                class="black-txt"
                v-else
              >
                <strong v-html="singleCode.commonTarif[box][0].rate" />
              </p>
              <p
                style="color: red !important;"
                v-if="singleCode.commonTarif[box][0].offDate"
                v-html="singleCode.commonTarif[box][0].offDate"
              />
            </div>
          </div>
        </div>
      </div>
            
      <!-- <h4>asdf</h4> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import { mapGetters } from 'vuex'
export default {
    props: {
        singleCode: {
            required:  true,
            type: Object
        }
    },
    data(){
        return{
            BYNCource: 0
        }
    },
    computed: {
        ...mapGetters({
            selectedCode: 'serv/getSelectedCode'
        })
    },
    async created(){
            await axios.get('https://www.cbr-xml-daily.ru/daily_json.js')
            .then(res =>{
                this.BYNCource = res.data.Valute.BYN.Value
            })
    },
    methods: {
        clearSingle(){
            this.$store.dispatch('serv/clearSingle')
        }
    }
}
</script>