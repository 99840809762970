<template>
  <section id="about">
    <div class="container">
      <div class="grey-box">
        <div class="row">
          <div class="col-lg-6">
            <h2 class="wow fadeInUp">
              {{ lang === 'ru' ? 'Наша компания' : 'Our company' }}
            </h2>
            <p
              class="black-txt mb-50 wow fadeInUp"
              data-wow-delay=".4s"
            >
              {{ lang === 'ru' ? 'Компания INTERVAL зарекомендовала себя на рынке брокерских услуг как слаженная, ответственная перед клиентом, динамично развивающаяся компания с персоналом из квалифицированных специалистов, способных помочь в решении любого вопроса, связанного с таможенным оформлением грузов. Головной офис компании находиться в Санкт-Петербурге. Мы осуществляем комплекс услуг по транспортно-экспедиторскому обслуживанию, таможенному оформлению с расчетом оптимальных схем транспортировки груза на всей территории РФ и за рубежом.' : 'INTERVAL company has established itself in the brokerage services market as a well-coordinated, responsible to the client, dynamically developing company with a staff of qualified specialists who can help in solving any issue related to customs clearance of goods. The head office of the company is located in St. Petersburg. We provide a range of services for freight forwarding, customs clearance with the calculation of optimal cargo transportation schemes throughout the Russian Federation and abroad.'
              }}
            </p>
            <div class="row">
              <div class="col-lg-6">
                <router-link
                  tag="button"
                  to="/about"
                  class="main-btn"
                >
                  {{ lang === 'ru' ? 'Подробнее о нас' : 'More about us' }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../assets/img/about.jpg"
          alt=""
          class="about-img"
        >
      </div>
    </div>
  </section>
</template>

<script>
const basicScroll = require('basicscroll')
import { mapGetters } from 'vuex';

export default {
    data(){
        return{
            current_year: new Date().getFullYear()
        }
    },
    computed: {
        ...mapGetters({
            lang: "lang/getLang",
            about: "serv/getAbout"
        })
    },
    mounted(){
        // from: элемент-экран
        const instance2 = basicScroll.create({
				elem: document.querySelector('.about-img'),
				from: 'middle-bottom',
				to: 'top-top',
				props: {
                    '--slide': {
						from: -18 + '%',
						to: 0 + '%'
					}
				}
			})
		instance2.start()
    }
}
</script>

<style scoped>
h2{
    padding-left: 50px;
}
h2:before{
    left: 0px !important;
}
.grey-box{
    background-color: #fff;
}
</style>