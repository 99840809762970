<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <router-link
                tag="a"
                to="/uslugi"
              >
                {{ lang === 'ru' ? 'Услуги' : 'Services' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ lang === 'ru' ? 'Отслеживание груза' : 'Cargo tracking' }}</span>
            </div>
            <h2>{{ lang === 'ru' ? 'Отслеживание груза' : 'Cargo tracking' }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="usual">
      <div class="container">
        <div class="row">
          <div class="col-lg-2">
            <img
              src="../assets/img/icons/ic9.svg"
              class="w-100 icon1 mb-30"
            >
          </div>
          <div class="col-lg-9 offset-lg-1">
            <p
              class="big-black mb-30"
              v-if="lang === 'ru'"
            >
              Благодаря собственной системе дислокации отправлений, Вы всегда будете в курсе нахождения <br><br>
              Вашего груза.
              Оформив заявку на перевозку в нашей компании, в личном кабинете, Вы получаете более
              подробную онлайн информацию по всему процессу работы с вашим грузом, описание пути
              следования отправления 24/7, комплект сопроводительной документации.
            </p>
            <p
              class="big-black mb-30"
              v-else
            >
              Due to our own system of dislocation of shipments, you will always be aware of the location of your
              cargo.<br><br>
              Having made an application for transportation in our company, in your personal account, you receive
              more detailed online information on the entire process of working with your cargo, a description of the
              route of departure 24/7, a set of accompanying documentation.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-30">
            <img class="icon1 mb-30">
            <form
              action=""
              class="order-form mb-30"
            >
              <div class="row">
                <div class="col-lg-12">
                  <p class="black-txt mb-10">
                    {{ lang === 'ru' ? 'Введите трек номер' : 'Enter the track number' }}
                  </p>
                </div>
                <div class="col-lg-9">
                  <input
                    v-model="search"
                    type="text"
                  >
                  <!-- :placeholder="lang === 'ru' ? 'Введите трек номер' : 'Enter the track number'" -->
                </div>
                <div class="col-lg-3">
                  <button class="main-btn">
                    {{ lang === 'ru' ? 'Искать' : 'Search' }}
                  </button>
                </div>
              </div>
            </form>  
            <div
              class="timeline"
              v-if="search !== ''"
            >
              <div
                class="timeline-item"
                v-for="(item, id) in tracking"
                :key="id"
              >
                <div class="timeline-dot" />
                <div>
                  <p class="big-black mb-5">
                    Получено адресатом
                  </p>
                  <p class="small-gray mb-0">
                    30 мая 2022, 14:27 188308, Гатчина
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 
    <section id="addition">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="dark-h3 mb-30">
              {{ lang === 'ru' ? 'Дополнительные услуги' : 'Additional services' }}
            </h3>
          </div>
          <div
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in services"
            :key="i"
          >
            <router-link
              tag="div"
              :to="`/uslugi/${item.slug}`"
              class="serv-box"
            >
              <img
                :src="item.ic1"
                class="icon1"
              >
              <img
                :src="item.ic2"
                class="icon2"
              >
              <h4>{{ item.name[lang] }}</h4>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
       return{
           search: '',
           services: [
                {
                    slug: 'mezhdunarodnaya',
                    name: {en: 'International transportation', ru: 'Международная перевозка'},
                    ic1: require('../assets/img/icons/ic7.svg'),
                    ic2: require('../assets/img/icons/ic7w.svg'),
                    col: 3
                },
                {
                    slug: 'tamozj',
                    name: {en: 'Customs clearance', ru: 'Таможенное оформление'},
                    ic1: require('../assets/img/icons/ic1.svg'),
                    ic2: require('../assets/img/icons/ic1w.svg'),
                    col: 6
                },
                {
                    slug: 'strahovanie',
                    name: {en: 'Cargo insurance', ru: 'Страхование грузов'},
                    ic1: require('../assets/img/icons/ic11.svg'),
                    ic2: require('../assets/img/icons/ic11w.svg'),
                    col: 3
                }
           ],
           tracking: [
               {
                   name: 'Получено адресатом',
                   txt: '30 мая 2022, 14:27 188308, Гатчина'
               },
               {
                   name: 'Прибыло в место вручения',
                   txt: '28 мая 2022, 09:30 188308, Гатчина'
               },
               {
                   name: 'Покинуло сортировочный центр',
                   txt: '28 мая 2022, 03:59 200960, Санкт-Петербург'
               },
               {
                   name: 'Сортировка',
                   txt: '26 мая 2022, 12:53 141816, Дмитров'
               },
               {
                   name: 'Прибыло в сортировочный центр',
                   txt: '28 мая 2022, 01:41 200983, Санкт-Петербург'
               },
               {
                   name: 'Покинуло место приема',
                   txt: '25 мая 2022, 19:09 141865, Некрасовский'
               },
               {
                   name: 'Принято в отделении связи',
                   txt: '25 мая 2022, 12:22 141865, Некрасовский'
               },
               {
                   name: 'Присвоен трек-номер',
                   txt: '24 мая 2022, 22:41 141733, Лобня'
               },

           ]
       }
   }
}
</script>

<style scoped>
.home::before{
    display: none;
}
</style>