<template>
  <v-app>
    <transition
      name="slide"
      mode="out-in"
    > 
      <Preloader v-if="preload" />
    </transition>

    <Header :glass-header="glassHeader" />
    <router-view />
    <Footer />
    <Tariffs
      v-if="singleCode"
      :single-code="singleCode"
    />
  </v-app>
</template>

<script>
import './assets/css/style.css'
import Header from './components/ui/Header.vue'
import Preloader from './components/ui/Preloader.vue'
import Footer from './components/ui/Footer.vue'
import Tariffs from './components/Tariffs.vue'
import { mapGetters } from 'vuex'

export default {
  components: {Header, Preloader, Footer, Tariffs},
  data(){
    return{
      preload: true,
	  glassHeader: false
    }
  },
  created(){
        this.$store.dispatch("serv/loadAbout")
    },
  computed: {
	  ...mapGetters({
		  singleCode: 'serv/getSingleCode'
	  })
  },
  mounted(){
    setTimeout(()=>{
      this.preload = false
    }, 4000)

	let vm = this

	window.document.body.onscroll = function() {
		let scrTop = window.document.documentElement.scrollTop
		if(pageYOffset > 500){
			vm.glassHeader = true
		}else{
			vm.glassHeader = false
		}
	}
		

  }
}
</script>

<style>
.slide-leave-active{
	transition: opacity .3s ease;
	opacity: 0;
	animation: slide-out .3s  ease-out forwards;
}
.slide-leave{
	opacity: 1;
	transform: translateX(0px);
}
.slide-enter-active{
	animation: slide-in .3s  ease-out forwards;
	opacity: 1;
}
@keyframes slide-out{
	0%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(50px);
	}
}
@keyframes slide-in{
	0%{
		transform: translateY(50px);
		opacity: 0;
	}
	100%{
		transform: translateY(0px);
		opacity: 1;
	}
}
</style>