<template>
  <section id="geo">
    <div class="container">
      <img
        src="../assets/img/wmap.svg"
        class="wmap wow fadeInRight"
        data-wow-duration="1.5s"
      >
      <div class="row">
        <div class="col-lg-6">
          <h2 class="wow fadeInUp">
            {{ lang === 'ru' ? 'Наша география' : 'Our geography' }}
          </h2>
          <p class="big-white mb-30 wow fadeInUp">
            {{ lang === 'ru' ? 'Доставляем грузы от 1 кг на импорт и экспорт из любой точки мира. Основные направления - Китай, Гонконг, Турция, Индия, Европа, США, Канада и ОАЭ. Проводим таможенное оформление на всех таможенных терминалах РФ. Осуществляем перевозку до адреса получателя под ключ.' : 'We deliver cargoes from 1 kg for import and export from anywhere in the world. The main destinations are China, Hong Kong, Turkey, India, Europe, USA, Canada and the UAE. We conduct customs clearance at all customs terminals of the Russian Federation. We carry out transportation to the recipient`s address on a turnkey basis.' }}
          </p>
          <router-link
            tag="a"
            to="/uslugi/po-rf"
          >
            <button class="main-btn wow fadeInUp">
              {{ lang === 'ru' ? 'Подробнее' : 'More detailed' }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    }
}
</script>