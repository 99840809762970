<template>
  <div class="drop-menu-mob">
    <div class="mb-4">
      <p
        @click="closeDrop"
        class="black-txt"
      >
        <span class="mdi mdi-close" /> Закрыть
      </p>
      <hr>
    </div>
    <h5>INTERVAL</h5>
    <ul @click="closeDrop">
      <router-link
        tag="li"
        :to="item.link"
        v-for="(item, i) in main_menu"
        :key="i"
      >
        {{ item[lang] }}
      </router-link>
    </ul>
    <h5>{{ lang === 'ru' ? 'Контакты' : 'Contacts' }}</h5>
    <p
      v-if="lang === 'ru'"
      class="small-grey"
    >
      191015, Санкт-Петербург, <br> ул. Шпалерная, д. 54,<br> БЦ "Золотая Шпалерная"
    </p>
    <p
      v-else
      class="small-grey"
    >
      54 Shpalernaya str.,<br> Saint-Petersburg, 191015,<br> Business Center "Zolotaya Shpalernaya"
    </p>
    <ul @click="closeDrop">
      <li>
        <a
          class="footer-link"
          href=""
        >+7 (812) 964-21-00</a>
      </li>
      <li>
        <a
          class="footer-link"
          href=""
        >info@intervalspb.ru</a>
      </li>
    </ul>
    <h5>{{ lang === 'ru' ? 'Услуги' : 'Services' }}</h5>
    <ul @click="closeDrop">
      <router-link
        tag="li"
        :to="item.link"
        v-for="(item, i) in services"
        :key="i"
      >
        {{ item[lang] }}
      </router-link>
    </ul>
    <h5>{{ lang === 'ru' ? 'ПЕРСОНАЛЬНЫЕ ДАННЫЕ' : 'Personal' }}</h5>
    <ul @click="closeDrop">
      <router-link
        tag="li"
        :to="item.link"
        v-for="(item, i) in personal"
        :key="i"
      >
        {{ item[lang] }}
      </router-link>
    </ul>
  </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex';
    export default {
        computed: {
            ...mapGetters({
                lang: "lang/getLang"
            })
        },
        methods: {
            closeDrop() {
                this.$emit('closeDrop')
            }
        },
        data() {
            return {
                main_menu: [{
                        ru: 'Поиск по ТН ВЭД',
                        en: 'Search by the Customs Code of the EAEU',
                        link: '/uslugi/tnved'
                    },
                    {
                        ru: 'Поиск по артикулу',
                        en: 'Searching by article',
                        link: '/uslugi/poisk-po-artikulu'
                    },
                    {
                        ru: 'Отслеживание груза',
                        en: 'Cargo tracking',
                        link: '/uslugi/otslezhivanie'
                    },
                    { ru: 'Примеры декларирования', en: 'Examples of declaration', link: '/uslugi/examples'},
                    {
                        ru: 'О компании',
                        en: 'About the company',
                        link: '/about'
                    }
                ],
                personal: [{
                        ru: 'Вход',
                        en: 'Login',
                        link: '/enter'
                    },
                    {
                        ru: 'Регистрация',
                        en: 'Sign up',
                        link: '/registration'
                    },
                    {
                        ru: 'Забыл пароль',
                        en: 'Forgot password',
                        link: '/registration'
                    },
                    {
                        ru: 'Мобильное приложение',
                        en: 'Mobile application',
                        link: '/pwa'
                    }
                ],
                services: [{
                        ru: 'Автомобильные',
                        en: 'Road transportation',
                        link: '/uslugi/mezhdunarodnaya/#road'
                    },
                    {
                        ru: 'Морские',
                        en: 'Sea transportation',
                        link: '/uslugi/mezhdunarodnaya/#sea'
                    },
                    {
                        ru: 'Железнодорожные',
                        en: 'Rail transportation',
                        link: '/uslugi/mezhdunarodnaya/#rail'
                    },
                    {
                        ru: 'Авиа',
                        en: 'Air transportation',
                        link: '/uslugi/mezhdunarodnaya/#air'
                    },
                    {
                        ru: 'Сборные',
                        en: 'Consolidated cargo',
                        link: '/uslugi/mezhdunarodnaya/#consolidated'
                    },
                    {
                        ru: 'Экспресс-доставка',
                        en: 'Express delivery',
                        link: '/uslugi/mezhdunarodnaya/#express'
                    },
                    {
                        ru: 'ВЭД-Агент',
                        en: 'Foreign trade agent',
                        link: '/uslugi/ved-agent'
                    },
                    {
                        ru: 'Таможенное оформление',
                        en: 'Customs clearance',
                        link: '/uslugi/tamozj'
                    },
                    {
                        ru: 'Страхование грузов',
                        en: 'Cargo insurance',
                        link: '/uslugi/strahovanie'
                    },
                    {
                        ru: 'Хранение грузов',
                        en: 'Cargo storage',
                        link: '/uslugi/sklad'
                    },
                ]
            }
        }
    }
</script>