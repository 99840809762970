import axios from 'axios'

const serv = {
	namespaced: true,
	state: {
        tnved: [],
        singleCode: null,
        selectedCode: '',
        globalSearch: '',
        articleSearch: [],
        services: [
            {
                slug: 'tamozj',
                name: {en: 'Customs clearance', ru: 'Таможенное оформление'},
                // txt: 'Расчет за 2 часа',
                descr: {ru: 'Многолетний опыт внешнеэкономической деятельности позволяет Компании предоставлять оптимальные условия для проведения таможенного оформления импорта и экспорта продукции. <br><br> Полный комплекс услуг по таможенному оформлению товаров: классификация товара в соответствии с ТН ВЭД ЕАЭС, расчет таможенных платежей, содействие в получение необходимых разрешительных документов при применение нетарифных мер регулирования, заполнение таможенной декларации, подача документов в таможенный орган и выпуск товара. Таможенное оформление грузов происходит исключительно при взаимодействии с таможенными органами и заблаговременном согласовании всех нюансов.', en: 'Many years of experience in foreign economic activity allows INTERVAL to provide optimal conditions for customs clearance of imports and exports of products.<br><br> A full range of services for customs clearance of goods: classification of goods in accordance with the Customs Code of the EAEU, calculation of customs payments, assistance in obtaining the necessary permits for the application of non-tariff regulatory measures, filling in a customs declaration, submission of documents to the customs authority and release of goods. Customs clearance of goods takes place exclusively in cooperation with customs authorities and advance coordination of all nuances.'},
                ic1: require('../assets/img/icons/ic1.svg'),
                ic2: require('../assets/img/icons/ic1w.svg'),
                col: 3
            },
            {
                slug: 'ved-agent',
                name: {en: 'Foreign trade agent', ru: 'ВЭД-Агент'},
                // txt: 'Расчет за 2 часа',
                // txt: 'Расчет за 2 часа',
                miniImg: {
                    en: 'https://new.interval.spb.ru/wp-content/uploads/2023/04/ved-agent-karta-angl.versiya.png',
                    ru: 'https://new.interval.spb.ru/wp-content/uploads/2023/04/ved-rus.png'
                },
                descr: {ru: 'Компания INTERVAL предлагает комплексный аутсорсинг внешнеэкономической деятельности.<br><br> Мы предоставляем - финансово-логистическое сопровождение при заключении контракта на импорт и экспорт товара, оплату и доставку в пункт назначения с последующим таможенным оформлением на свою компанию, с соблюдением всех правовых норм.<br><br> Делегируйте свои задачи профессиональному ВЭД-Агенту.', 
                en: 'INTERVAL company offers comprehensive outsourcing of foreign economic activity.<br><br> We provide financial and logistical support when concluding a contract for the import and export of goods, payment and delivery to the destination with subsequent customs clearance to your company, in compliance with all legal norms.<br><br> Delegate your tasks to a professional foreign trade agent.'},
                ic1: require('../assets/img/icons/ic3.svg'),
                ic2: require('../assets/img/icons/ic3w.svg'),
                col: 3
            },
            {
                slug: 'podbor',
                name: {en: 'Selection of goods abroad', ru: 'Подбор товара за рубежом'},
                // txt: 'Расчет за 2 часа',
                descr: {
                    ru: 'Планируя экспортную или импортную деятельность для увеличения продаж за счет выхода на новые зарубежные рынки, клиенту необходима комплексная услуга по содействию в поиске иностранного товара с определенными характеристиками, не представленного на целевом рынке РФ, подбор иностранного поставщика, заключение с ним контракта. <br><br>  Специалисты INTERVAL готовы помочь в реализации подобной задачи профессионально и быстро.', 
                    en: 'When planning export or import activities to increase sales by entering new foreign markets, the client needs a comprehensive service to assist in the search for foreign goods with certain characteristics that are not represented in the target market of the Russian Federation, the selection of a foreign supplier, the conclusion of a contract with him. <br><br>  INTERVAL specialists are ready to help in the implementation of such a task professionally and quickly.'
                },
                listTitle: {en: 'We offer:', ru: 'Мы предлагаем:'},
                list:{
                    ru: ['подбор простых и сложных товаров, единичных и крупных партий;', 'поиск проверенного поставщика в конкретной стране, готового обеспечить проверку качества товара с последующей отправкой (по запросу);', 'закупка и доставка товара «под наш контракт» из любой точки Европы, Азии и США.'],
                    en: ['selection of simple and complex goods, single and large batches;', 'search for a trusted supplier in a specific country who is ready to provide quality control of the goods with subsequent shipment (on request);', 'purchase and delivery of goods "under our contract" from anywhere in Europe, Asia and the USA.']
                },
                ic1: require('../assets/img/icons/ic4.svg'),
                ic2: require('../assets/img/icons/ic4w.svg'),
                col: 6
            },
            {
                slug: 'poisk-po-artikulu',
                name: {en: 'Searching information by article', ru: 'Поиск информации по артикулу'},
                // txt: 'Расчет за 2 часа',
                ic1: require('../assets/img/icons/ic2.svg'),
                ic2: require('../assets/img/icons/ic2w.svg'),
                col: 3
            },
            {
                slug: 'tnved',
                name: {en: 'Search by the Customs Code of the EAEU (HS)', ru: 'Поиск информации по ТН ВЭД'},
                // txt: 'Расчет за 2 часа',
                ic1: require('../assets/img/icons/ic5.svg'),
                ic2: require('../assets/img/icons/ic5w.svg'),
                col: 3
            },
            {
                slug: 'sertifikaciya',
                name: {en: 'Permissive documentation', ru: 'Разрешительная документация'},
                descr: {
                    ru: 'При импорте товаров на территорию РФ или экспорте с территории РФ на определенные группы товаров распространяются нетарифные меры регулирования. Соблюдение нетарифных мер регулирования заключается в обязательном оформлении различной разрешительной документации. Нетарифные меры регулирования - это комплекс мер регулирования внешней торговли товарами, осуществляемых путем введения количественных и иных запретов и ограничений экономического характера, которые установлены международными договорами государств-членов Евразийского экономического союза (ЕАЭС), решениями Комиссии ЕАЭС и нормативными правовыми актами государств-членов ЕАЭС, изданными в соответствии с международными договорами государств-членов ЕАЭС. <br><br>Наша компания также организует ввоз и таможенное оформление образцов для проведения испытаний в аккредитованных сертификатов/деклараций соответствия на товары для дальнейшего ввоза продукции на территорию РФ и её реализации.', 
                    en: 'When importing goods into the territory of the Russian Federation or exporting from the territory of the Russian Federation, non-tariff regulatory measures apply to certain groups of goods. Compliance with non-tariff regulatory measures consists in mandatory registration of various permits. Non-tariff regulatory measures are a set of measures to regulate foreign trade in goods carried out by introducing quantitative and other prohibitions and restrictions of an economic nature, which are established by international treaties of the member States of the Eurasian Economic Union (EAEU), decisions of the EAEU Commission and regulatory legal acts of the EAEU member States issued in accordance with international treaties of the EAEU member States. <br><br> Our company also organizes the import and customs clearance of samples for testing in accredited laboratories, followed by obtaining certificates /declarations of conformity for goods for further import of products into the territory of the Russian Federation and its sale.'
                },
                listTitle: {en: 'INTERVAL provides licensing, certification and registration services for the following permits:', ru: 'INTERVAL обеспечивает лицензирование,  сертификацию и предоставляет услуги оформления следующих разрешительных документов:'},
                list:{
                    ru: [
                        'Сертификаты и декларация ТР ЕАЭС/ ГОСТ Р',
                        'Сертификаты происхождения',
                        'Экспортный контроль',
                        'Нотификация ФСБ',
                        'Лицензии Минпромторга РФ',
                        'Свидетельства о государственной регистрации продукции',
                        'ФСБ РЧЦ Роскомнадзора Минкультуры',
                        'Сертификация средств связи',
                    ],
                    en: [
                        'Certificates and declaration of the EAEU TR/ GOST R',
                        'Certificates of origin',
                        'Export control',
                        'Notification of the Federal Security Service',
                        'Licenses of the Ministry of Industry and Trade of the Russian Federation',
                        'Certificates of state registration of products',
                        'The Federal Security Service Radio Frequency Center of Roskomnadzor Ministry of Culture',
                        'Certification of communication facilities',
                    ]
                },
                // txt: 'Расчет за 2 часа',
                ic1: require('../assets/img/icons/ic6.svg'),
                ic2: require('../assets/img/icons/ic6w.svg'),
                col: 3
            },
            {
                slug: 'mezhdunarodnaya',
                name: {en: 'International transportation', ru: 'Международная перевозка'},
                // txt: 'Расчет за 2 часа',
                ic1: require('../assets/img/icons/ic7.svg'),
                ic2: require('../assets/img/icons/ic7w.svg'),
                col: 3
            },
            {
                slug: 'po-rf',
                name: {en: 'Transportation across the Russian Federation', ru: 'Перевозка по РФ'},
                // txt: 'Расчет за 2 часа',
                ic1: require('../assets/img/icons/ic8.svg'),
                ic2: require('../assets/img/icons/ic8w.svg'),
                col: 3
            },
            {
                slug: 'otslezhivanie',
                name: {en: 'Cargo tracking', ru: 'Отслеживание груза'},
                // txt: 'Расчет за 2 часа',
                ic1: require('../assets/img/icons/ic9.svg'),
                ic2: require('../assets/img/icons/ic9w.svg'),
                col: 3
            },
            {
                slug: 'integraciya',
                name: {en: 'Integration of our company`s CRM', ru: 'Интеграция с CRM нашей компании'},
                // txt: 'Расчет за 2 часа',
                descr: {
                    ru: 'INTERVAL предлагает своим клиентам, новый формат сотрудничества в рамках электронного документооборота. <br><br> Специалисты отдела сопровождения INTERVAL 1С, индивидуально разработают под CRM – клиента программный модуль обмена данными через 1С систему. Ведение всех логистических процессов сводится к отказу от почтового сервиса и переходу взаимодействия к единому модулю CRM INTERVAL, который позволяет максимально удобно, оперативно оформлять и отправлять электронные заявки, декларации, вести переписку с менеджерами компании, контролировать документооборот и отслеживать все свои грузы.', 
                    en: 'INTERVAL offers its customers a new format of cooperation within the framework of electronic document management. <br><br> Specialists of the INTERVAL 1C support department will individually develop a software module for data exchange through the 1C system for a CRM client. The management of all logistics processes is reduced to the rejection of the postal service and the transition of interaction to a single CRM INTERVAL module, which allows you to conveniently, promptly issue and send electronic applications, declarations, correspond with company managers, control document flow and track all your shipments.'
                },
                ic1: require('../assets/img/icons/ic10.svg'),
                ic2: require('../assets/img/icons/ic10w.svg'),
                col: 3
            },
            {
                slug: 'strahovanie',
                name: {en: 'Cargo insurance', ru: 'Страхование грузов'},
                // txt: 'Расчет за 2 часа',
                descr: {
                    ru: 'Для минимизации рисков при международной транспортировке грузов важно оформить полис страхования, определяющий права, обязанности и ответственность сторон. Его наличие гарантирует возмещение стоимости, в случаях утери, уничтожения или повреждения. Клиент может воспользоваться услугами страхования в нашей компании или оформить их самостоятельно в страховой компании.', 
                    en: 'To minimize risks during international cargo transportation, it is important to issue an insurance policy that defines the rights, obligations and responsibilities of the parties. Its presence guarantees reimbursement of the cost in cases of loss, destruction or damage. The client can use the insurance services in our company or arrange them independently in an insurance company.'
                },
                ic1: require('../assets/img/icons/ic11.svg'),
                ic2: require('../assets/img/icons/ic11w.svg'),
                col: 3
            },
            {
                slug: 'sklad',
                name: {en: 'Consolidation warehouses abroad and in the Russian Federation', ru: 'Склады консолидации за рубежом и в РФ'},
                // txt: 'Расчет за 2 часа',
                descr: {
                    ru: 'Компания INTERVAL предлагает услуги ответственного хранения товара на оборудованных складах с автоматизированной системой управления международного стандарта, позволяющей контролировать все процессы: сбор и хранение небольших партий грузов от нескольких отправителей, накопление и сортировку на складе компании-грузоперевозчика и непосредственно формирование партий разрозненных грузов, которые будут отправлены на одном транспортном средстве, по одному транспортному документу, в одном направлении. Таким образом оптимизация финансовых средств - экономия средств для клиента.', 
                    en: 'INTERVAL company offers services of responsible storage of goods in equipped warehouses with an automated management system of international standard, which allows you to control all processes: collection and storage of small shipments from several senders, accumulation and sorting in the warehouse of the cargo carrier company and directly the formation of batches of disparate cargoes that will be sent on one vehicle, one transport document, in one direction. Thus, the optimization of financial resources is a cost savings for the client.'
                },
                ic1: require('../assets/img/icons/ic12.svg'),
                ic2: require('../assets/img/icons/ic12w.svg'),
                col: 3
            },
            {
                slug: 'expedirovanie',
                name: {en: 'Freight forwarding in the port and at the temporary storage warehouse', ru: 'Экспедирование грузов в порту и на СВХ'},
                // txt: 'Расчет за 2 часа',
                descr: {
                    ru: 'Внутрипортовое экспедирование от нашей компании - партнерская работа, с представителями портовых служб. Мы предлагаем заключение единого договора на комплекс услуг, объединяющий широкий спектр операций по перевалке, хранению продукции на СВХ, подготовке разрешительной документации, таможенному оформлению и транспортировке, направленный на минимизацию времени по нахождению груза в порту и скорейшей его отгрузки получателю. Все участники логистической цепочки координируются опытным экспедитором, отвечающим за качество и безопасность перевозки.', 
                    en: 'Intraport forwarding from our company is a partnership work with representatives of port services. We offer the conclusion of a single contract for a range of services that combines a wide range of operations for transshipment, storage of products at temporary storage warehouse, preparation of permits, customs clearance and transportation, aimed at minimizing the time of finding cargo in the port and its speedy shipment to the recipient. All participants i logistics chain are coordinated by an experienced freight forwarder responsible for the quality and safety of transportation.'
                },
                ic1: require('../assets/img/icons/ic13.svg'),
                ic2: require('../assets/img/icons/ic13w.svg'),
                col: 6
            },
            {
                slug: 'dostavka',
                name: {en: 'Cargo delivery for marketplaces', ru: 'Доставка грузов для маркетплейсов'},
                // txt: 'Расчет за 2 часа',
                descr: {
                    ru: 'Если Ваша компания занимается продвижением зарубежных товаров на маркетплэйсах РФ, заинтересована в больших партиях или срочных доставках, но сталкивается с затратами времени на организацию «самостоятельной» логистики, с падением качества перевозки, проблемами в получении официальных документов на товары при прохождении таможенных процедур - оптимизируйте рабочий ресурс и сократите расходы на доставку, прибегнув к услугам нашей транспортной компании.', 
                    en: 'If your company is engaged in the promotion of foreign goods on the marketplaces of the Russian Federation, is interested in large batches or urgent deliveries, but faces time costs for organizing "independent" logistics, with a drop in the quality of transportation, problems in obtaining official documents for goods during customs procedures - optimize the working resource and reduce shipping costs by resorting to the services our transport company.'
                },
                listTitle: {ru: 'INTERVAL предлагает своим клиентам «белую» контрактную схему ввоза товар с высоким качеством услуг перевозки:', en: 'INTERVAL offers its customers a "white" contract scheme for importing goods with high quality transportation services:'},
                list:{
                    ru: [
                        'официальный денежный перевод поставщику;',
                        'заявление полной стоимости товара при таможенном оформлении (оплата всех таможенных платежей, получение необходимых сертификатов);',
                        'официальная перевозка груза через границу;',
                        'официальный комплект закрывающих документов от компании импортера.',
                    ],
                    en: [
                        'official money transfer to the supplier;',
                        'statement of the full value of the goods during customs clearance (payment of all customs duties, obtaining the necessary certificates);',
                        'official shipping across the border;',
                        'an official set of closing documents from the importer`s company.',
                    ]
                },
                ic1: require('../assets/img/icons/ic14.svg'),
                ic2: require('../assets/img/icons/ic14w.svg'),
                col: 3
            },
        ],
        about: null
  	},
	mutations: {
        SET_TNVED(state, tnved){
            state.tnved = tnved
        },
        SET_SINGLE_CODE(state, singleCode){
            state.singleCode = singleCode
        },
        SET_ARTICLE_SEARCH(state, articleSearch){
            state.articleSearch = articleSearch
        },
        SET_ABOUT(state, about){
            state.about = about
        },
	},
	actions: {
        loadTnved({commit}){
            axios
            .get(`https://declarant.by/proxy.php?url=read/nsiTnVedsWithEtt`)
            .then(res =>{
                // console.log(res)
                commit("SET_TNVED", res.data)
            })
        },
        loadSingleCode({commit, state}, param){
            axios
            .get(`https://declarant.by/proxy.php?url=read/nsiTarifs?code=${param}&direction=1&date=${'2022-06-28'}`)
            .then(res =>{
                // console.log(res.data)
                state.selectedCode = param
                commit("SET_SINGLE_CODE", res.data)
            })
        },
        setGlobalSearch({state}, code){
            state.globalSearch = code
        },
        clearSingle({state}){
            state.singleCode = null
            state.selectedCode = ''
        },
        clearGlobal({state}){
            state.globalSearch = ''
        },
        async loadArticleSearch({commit}, payload){
            const url = payload ? 
            `https://new.interval.spb.ru/wp-json/int/v1/get/tnved?tnved=${payload}` : 
            'https://new.interval.spb.ru/wp-json/int/v1/get/articles'

           await axios.get(url)
            .then(res =>{
                commit("SET_ARTICLE_SEARCH", res.data)
            })
        },
        loadAbout({commit}){
            axios.get('https://new.interval.spb.ru/wp-json/int/v1/get/page/about')
            .then(res =>{
                commit("SET_ABOUT", res.data)
            })
        }
	},
	getters: {
        getGlobalSearch(state){
            return state.globalSearch
        },
        getSelectedCode(state){
            return state.selectedCode
        },
        getTnved(state){
            return state.tnved
        },
        getSingleCode(state){
            return state.singleCode
        },
        getServ(state){
            return state.services
        },
        getSingle: (state) => (slug) => {
			return state.services.find(item => item.slug === slug)
		},
        getAbout(state){
            return state.about
        },
        getArticleSearch: s => s.articleSearch.map((i) => {
            return {
              ...i,
              search: `${i.tnved}-${i.name}-${i.proizvoditel}-${i.opisanie_3}`
            }
        }),
	}
}

export default serv


