<template>
  <section id="usual">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tnved-grey-box">
            <h3 class="mb-10">
              {{ id }}
            </h3>
            <p class="black-txt mb-30">
              <strong>Краткое наименование:</strong> {{ tnved.KR_NAIM }}
            </p>
            <h3>Импортная пошлина: {{ tnved.TNVED.IMP }}%</h3>
            <h3>НДС: {{ tnved.TNVED.NDS }}%</h3>
            <h3>: {{ tnved.TNVED.AKC }}</h3>
            <pre>{{ getLook(tnved.TNVED.AKCEDI) }}</pre>
          </div>
        </div>
      </div>
    </div> 
  </section> 
</template>

<script>
import axios from 'axios'
import { getLook } from '../catalogs/tnvlook.js'

export default {
    props: ['id'],
    data(){
        return {
            tnved: null
        }
    },
    watch: {
        id(){
            this.tnved = null 
            this.getTnved()
        }
    },
    methods: {
        getTnved(){
            axios
            .get(`https://api1.tks.ru/tnved.json/json/44ae92a96a2445658bcc875ecd3ec67b/${this.id}.json`)
            .then(res =>{
                this.tnved = res.data
            })
        },
    },
    created(){
        this.getTnved();
    }
}
</script>
