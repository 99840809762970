<template>
  <section id="mapbox">
    <yandex-map
      :coords="[59.949589, 30.374102]"
      :scroll-zoom="false"
      :controls="[]"
      :zoom="16"
      v-if="showMap"
    >
      <ymap-marker 
        :coords="coordinates"
        :icon="markerIcon"
        :marker-id="markerIcon.id"
      >
        234
      </ymap-marker>
    </yandex-map>
    <div
      class="container wow slideInUp"
      data-wow-duration="1.2s"
    >
      <div class="white-box">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="mb-50">
              {{ lang === 'ru' ? 'Наши контакты' : 'Our contacts' }}
            </h2>
            <!-- <p class="big-black mb-50">Нажмите на карточку, чтобы посмотреть локацию на карте</p> -->
          </div>
          <div class="col-lg-8 offset-lg-2 wow fadeInUp">
            <div class="serv-box text-center">
              <img
                src="../assets/img/marker.svg"
                class="icon1 mx-auto"
              >
              <img
                src="../assets/img/wmarker.svg"
                class="icon2 mx-auto"
              >
              <h4 class="mb-10">
                {{ lang === 'ru' ? 'Наш офис' : 'Our office' }}:
              </h4>
              <p class="black-txt">
                <span class="mdi mdi-map-marker" /> 
                <span v-if="lang === 'ru'">Адрес: 191015, Санкт-Петербург, ул. Шпалерная, д. 54, БЦ "Золотая Шпалерная"</span>
                <span v-else>Address: 54 Shpalernaya str., Business center "Zolotaya Shpalernaya", 191015, <br> Saint-Petersburg</span>
              </p>
              <p class="black-txt">
                <span class="mdi mdi-phone" /> {{ lang === 'ru' ? 'Телефон' : 'Phone' }}: +7 (812) 964-21-00
              </p>
              <p class="black-txt">
                <span class="mdi mdi-email" /> E-mail: info@intervalspb.ru
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return{
          showMap: false,
            coordinates: [59.949181, 30.373806],
            markerIcon: {
                id:234,
			  	layout: 'default#imageWithContent',
			    imageHref: require('../assets/img/pin.svg'),
			    imageSize: [55, 55],
			    imageOffset: [-25, -45],
			},
        }
    },
    mounted(){
      setTimeout(() => {
        this.showMap = true
      }, 50)
    }
}
</script>

<style scoped>
.ymap-container{
    height: 700px;
}
.white-box h2:before{
  left: calc(50% - 100px);
}
</style>