import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth'
import serv from './serv'
import lang from './lang'
import sales from './sales'


export default new Vuex.Store({
	modules: {
		serv,
		lang,
		sales,
		auth
	}
}) 