<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <router-link
                tag="a"
                to="/uslugi"
              >
                {{ lang === 'ru' ? 'Услуги' : 'Services' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ single(id).name[lang] }}</span>
            </div>
            <h2>{{ single(id).name[lang] }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section
      id="usual"
      class="pb-0"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-2">
            <img
              :src="single(id).ic1"
              class="w-100 icon1 mb-30"
            >
          </div>
          <div class="col-lg-9 offset-lg-1 mb-30">
            <p
              class="big-black mb-30"
              v-html="single(id).descr[lang]"
            />
            <h3
              v-if="single(id).listTitle"
              class="dark-h3 mt-4 mb-10"
            >
              {{ single(id).listTitle[lang] }}
            </h3>
            <ul v-if="single(id).list">
              <li
                v-for="(item, i) in single(id).list[lang]"
                :key="i"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div
            class="col-lg-8 offset-lg-2"
            v-if="single(id).miniImg"
          >
            <img
              :src="single(id).miniImg[lang]"
              class="w-100"
            >
          </div>
          <!-- <div class="col-lg-12">
                        <h3 class="dark-h3">{{lang === 'ru' ? 'Наши предложения' : 'Our offeers'}}</h3>
                    </div>
                    <div :class="`col-lg-${item.col}`" v-for="(item, i) in sales" :key="i" class="wow fadeInUp">
                        <div class="sale-box">
                            <div>
                                <h4>{{item.name}}</h4>
                                <p class="white-txt" v-html="item.txt"></p>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </section>

    <section
      id="usual"
      v-if="id === 'tamozj'"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-50">
            <h3 class="dark-h3">
              {{ lang === 'ru' ? 'Основные направления таможенного оформления' : 'The main directions of customs clearance' }}
            </h3>
            <ul class="markers">
              <li
                v-for="(item, i) in markers"
                :key="i"
                @click="setActive(i)"
                :class="{active : active === i}"
              >
                <span style="opacity:.5;">#</span>{{ item.txt[lang] }}
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <yandex-map
              :coords="coordinates"
              :scroll-zoom="false"
              :controls="['zoomControl']"
              :zoom="zoom"
              v-if="showMap"
            >
              <ymap-marker
                v-for="(marker, idx) in markers"
                :key="idx"
                :coords="marker.coord"
                :icon="markerIcon(marker.txt[lang])"
                :marker-id="`marker${idx}`"
              />
            </yandex-map>
          </div>
        </div>
      </div>
    </section>

    <!-- <section id="usual" style="background-color:#ECECEC;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h3 class="dark-h3">Почему выбирают нас ?</h3>
                    </div>
                    <div class="col-lg-3">
                        <div class="icon-box">
                            <img src="../assets/img/icons/ic1.svg" alt="">
                                <p class="big-black">Организуем более тысячи перевозок в год</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="icon-box">
                            <img src="../assets/img/icons/ic1.svg" alt="">
                                <p class="big-black">Надежная и современная техника</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="icon-box">
                            <img src="../assets/img/icons/ic1.svg" alt="">
                                <p class="big-black">Широкий спектр дополнительных услуг</p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="icon-box">
                            <img src="../assets/img/icons/ic1.svg" alt="">
                                <p class="big-black">Обладаем необходимыми сертификатами</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    

    <section id="order">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="dark-h3 mb-10">
              {{ lang === 'ru' ? 'Заказать услугу' : 'Order service' }}
            </h3>
            <p class="black-txt mb-30">
              {{ lang === 'ru' ? 'Наши менеджеры быстро ответят на заявку' : 'Our managers will quickly respond to the application' }}
            </p>
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <form
                  @submit.prevent="submitForm"
                  class="order-form"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'ФИО' : 'Name'"
                        v-model="fio"
                        :class="{errorInp :$v.fio.$dirty && !$v.fio.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'Телефон' : 'Phone'"
                        v-model="phone"
                        :class="{errorInp :$v.phone.$dirty && !$v.phone.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        placeholder="E-mail"
                        v-model="email" 
                        :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
                      >
                    </div>
                    <div class="col-lg-12">
                      <input
                        type="text"
                        :value="getServVal(`${lang === 'ru' ? 'Услуга' : 'Service'}: ${id === 'ved-agent' ? vedMore[lang] : single(id).name[lang]}`)"
                        disabled
                      >
                    </div>
                    <div class="col-lg-12">
                      <textarea
                        :placeholder="lang === 'ru' ? 'Комментарий' : 'Comment'"
                        rows="5"
                        v-model="comment"
                      />
                    </div>
                    <div class="col-lg-12 text-center">
                      <p
                        class="small-gray"
                        v-if="lang === 'ru'"
                      >
                        Нажимая "Отправить", вы соглашаетесь с  <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                          class="orange"
                        >Политикой конфиденциальности сайта</a>
                      </p>
                      <p
                        class="small-gray"
                        v-else
                      >
                        By clicking "Submit", you agree with the <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/website-privacy-policy-and-personal-data-processing-interval.pdf"
                          class="orange"
                        >Website Privacy Policy</a>
                      </p>
                      <br>
                      <loader v-if="loading" />
                      <button
                        class="main-btn"
                        v-else
                      >
                        {{ lang === 'ru' ? 'Отправить' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr class="mt-40">
          </div>
        </div>
      </div>
    </section>

    <section id="addition">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="dark-h3 mb-30">
              {{ lang === 'ru' ? 'Дополнительные услуги' : 'Additional services' }}
            </h3>
          </div>
          <div
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in services"
            :key="i"
          >
            <router-link
              tag="div"
              :to="`/uslugi/${item.slug}`"
              class="serv-box"
            >
              <img
                :src="item.ic1"
                class="icon1"
              >
              <img
                :src="item.ic2"
                class="icon2"
              >
              <h4>{{ item.name[lang] }}</h4>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formGlobal from '@/mixins/formGlobal'

export default {
    props: ["id"],
    mixins: [formGlobal],
    computed: {
        ...mapGetters({
            single: "serv/getSingle",
            sales: "sales/getSales",
            lang: "lang/getLang",
        })
    },
    methods: {
        openAccordeon(i){
            this.faq[i].active = !this.faq[i].active
        },
        setActive(i){
            if(i === this.active){
                this.coordinates = [55.755863, 37.617700],
                this.active = null
                this.zoom = 4
                return
            }
            this.zoom = 8
            this.active = i
            setTimeout(() => {
                this.coordinates = this.markers[i].coord
            }, 400)
        },
        markerIcon(content) {
            return{
                id:234,
				layout: 'default#imageWithContent',
			    imageHref: require('../assets/img/pin.svg'),
			    imageSize: [35, 35],
			    imageOffset: [0, 0],
                content: '123 v12',
                contentOffset: [35, 7],
                contentLayout: `<div style="background:#fff; width: fit-content; color: #000; font-weight: 400;padding:3px 5px;border-radius: 4px; font-size:12px;">${content}</div>`
            }
		}
    },
    data(){
       return{
            showMap: false,
            vedMore: {
                ru: 'Подробнее о наличии товара',
                en: 'More about product availability'
            },
           coordinates: [55.755863, 37.617700],
            zoom: 4,
            active: null,
            markers: [
                {coord: [59.938955, 30.315644], txt: {en: 'Saint Petersburg', ru: 'СанктПетербург'}},
                {coord: [55.755864, 37.617698], txt: {en: 'Moscow', ru: 'Москва'}},
                {coord: [43.585472, 39.723098], txt: {en: 'Sochi', ru: 'Сочи'}},
                {coord: [45.035470, 38.975313], txt: {en: 'Krasnodar', ru: 'Краснодар'}},
                {coord: [43.115542, 131.885494], txt: {en: 'Vladivostok', ru: 'Владивосток'}},
                {coord: [55.030204, 82.920430], txt: {en: 'Novosibirsk', ru: 'Новосибирск'}},
                {coord: [56.838011, 60.597474], txt: {en: 'Yekaterinburg', ru: 'Екатеринбург'}},
                {coord: [44.723771, 37.768813], txt: {en: 'Novorossiysk', ru: 'Новороссийск'}}
            ],
           services: [
                {
                    slug: 'ved-agent',
                    name: 'ВЭД-Агент',
                    txt: 'Расчет за 2 часа',
                    ic1: require('../assets/img/icons/ic3.svg'),
                    ic2: require('../assets/img/icons/ic3w.svg'),
                    col: 3
                },
                {
                    slug: 'podbor',
                    name: 'Подбор товара за рубежом',
                    txt: 'Расчет за 2 часа',
                    ic1: require('../assets/img/icons/ic4.svg'),
                    ic2: require('../assets/img/icons/ic4w.svg'),
                    col: 6
                },
                {
                    slug: 'poisk-po-artikulu',
                    name: 'Поиск информации по артикулу',
                    txt: 'Расчет за 2 часа',
                    ic1: require('../assets/img/icons/ic2.svg'),
                    ic2: require('../assets/img/icons/ic2w.svg'),
                    col: 3
                },
           ]
       }
   },
   mounted(){
      setTimeout(() => {
        this.showMap = true
      }, 50)
    }
}
</script>

<style scoped>
.home::before {
    display: none;
}
.ymap-container{
    height: 550px;
}
</style>