<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <router-link
            tag="a"
            to="/"
          >
            <img
              src="../../assets/img/logo.svg"
              class="logo"
            >
          </router-link>
          <p
            class="small-gray"
            v-if="lang === 'ru'"
          >
            Международные перевозки <br> и таможенное оформление
          </p>
          <p
            class="small-gray"
            v-else
          >
            International transportation <br> and customs clearance
          </p>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <h5>{{ lang === 'ru' ? 'КАРТА САЙТА' : 'SITE MAP' }}</h5>
            </div>
            <div class="col-lg-4">
              <ul>
                <router-link
                  tag="li"
                  :to="item.link"
                  v-for="(item, i) in main_menu"
                  :key="i"
                >
                  {{ item[lang] }}
                </router-link>
              </ul>
            </div>
            <div class="col-lg-8">
              <ul class="double">
                <router-link
                  tag="li"
                  :to="item.link"
                  v-for="(item, i) in services"
                  :key="i"
                >
                  {{ item[lang] }}
                </router-link>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <h5>{{ lang === 'ru' ? 'Персональные данные' : 'personal' }}</h5>
          <ul>
            <router-link
              tag="li"
              to="/"
            >
              <span class="mdi mdi-account" /> {{ lang === 'ru' ? 'Вход' : 'Login' }}
            </router-link>
            <router-link
              tag="li"
              to="/"
            >
              <span class="mdi mdi-pencil" /> {{ lang === 'ru' ? 'Регистрация' : 'Sign up' }}
            </router-link>
            <router-link
              tag="li"
              to="/"
            >
              <span class="mdi mdi-lock" /> {{ lang === 'ru' ? 'Забыл пароль' : 'Forgot password' }}
            </router-link>
            <router-link
              tag="li"
              to="/pwa"
            >
              <span class="mdi mdi-cellphone-play" /> {{ lang === 'ru' ? 'Мобильное приложение' : 'Mobile application' }}
            </router-link>
          </ul>
        </div>
                
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <h5>{{ lang === 'ru' ? 'Контакты' : 'Contacts' }}</h5>
            </div>
            <div class="col-lg-6">
              <p
                class="small-grey"
                v-if="lang === 'ru'"
              >
                191015, Санкт-Петербург, <br>
                ул. Шпалерная, д. 54,<br>
                БЦ "Золотая Шпалерная"
              </p>
              <p
                class="small-grey"
                v-else
              >
                54 Shpalernaya str.,  <br>
                Saint-Petersburg, 191015, <br>
                Business Center "Zolotaya Shpalernaya"
              </p>
            </div>
            <div class="col-lg-6">
              <p>
                <a
                  href=""
                  class="footer-link"
                >+7 (812) 964-21-00</a>
              </p>
              <p>
                <a
                  href=""
                  class="footer-link"
                >info@intervalspb.ru</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <h5>{{ lang === 'ru' ? 'ПОДПИСКА НА ОБНОВЛЕНИЯ' : ' SUBSCRIBE TO UPDATES' }}</h5>
            </div>
            <div class="col-lg-7">
              <input
                type="text"
                placeholder="E-mail"
              >
            </div>
            <div class="col-lg-5">
              <button
                class="main-btn"
                style="width:100%;"
              >
                {{ lang === 'ru' ? 'Подписаться' : 'Subscribe' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p class="small-grey">
          © {{ lang === 'ru' ? 'ООО "Интервал" 2015 - 2023' : 'LLC "Interval" 2015 – 2023' }}
        </p>
        <p class="small-grey">
          <a
            class="white"
            :href="lang === 'ru' ? 'https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf' : 'https://new.interval.spb.ru/wp-content/uploads/2023/07/website-privacy-policy-and-personal-data-processing-interval.pdf'"
            target="_blank"
          >{{ lang === 'ru' ? 'Политика конфиденциальности сайта' : 'Website Privacy Policy' }}</a>
        </p>
        <p class="small-grey">
          {{ lang === 'ru' ? 'Разработка сайта - Webink.' : 'Website development - Webink.' }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return {
            main_menu: [
                { ru: 'Поиск по ТН ВЭД', en: 'Search by the Customs Code of the EAEU', link: '/uslugi/tnved'},
                { ru: 'Поиск по артикулу', en: 'Searching by article', link: '/uslugi/poisk-po-artikulu'},
                { ru: 'Отслеживание груза', en: 'Cargo tracking', link: '/uslugi/otslezhivanie'},
                { ru: 'Примеры декларирования', en: 'Examples of declaration', link: '/uslugi/examples'},
                { ru: 'О компании', en: 'About the company', link: '/about'}
            ],
            personal: [
                { ru: 'Вход', en: 'Login', link: '/enter'},
                { ru: 'Регистрация', en: 'Sign up', link: '/registration'},
                { ru: 'Забыл пароль', en: 'Forgot password', link: '/registration'},
                { ru: 'Мобильное приложение', en: 'Mobile application', link: '#'}
            ],
            services: [
                {ru: 'Автомобильные', en: 'Road transportation', link: '/uslugi/mezhdunarodnaya/#road'},
                {ru: 'Морские', en: 'Sea transportation', link: '/uslugi/mezhdunarodnaya/#sea'},
                {ru: 'Железнодорожные', en: 'Rail transportation', link: '/uslugi/mezhdunarodnaya/#rail'},
                {ru: 'Авиа', en: 'Air transportation', link: '/uslugi/mezhdunarodnaya/#air'},
                {ru: 'Сборные', en: 'Consolidated cargo', link: '/uslugi/mezhdunarodnaya/#consolidated'},
                {ru: 'Экспресс-доставка', en: 'Express delivery', link: '/uslugi/mezhdunarodnaya/#express'},
                {ru: 'ВЭД-Агент', en: 'Foreign trade agent', link: '/uslugi/ved-agent'},
                {ru: 'Таможенное оформление', en: 'Customs clearance', link: '/uslugi/tamozj'},
                {ru: 'Страхование грузов', en: 'Cargo insurance', link: '/uslugi/strahovanie'},
                {ru: 'Хранение грузов', en: 'Cargo storage', link: '/uslugi/sklad'},
            ]
        }
    }
}
</script>
