import axios from 'axios'

const auth = {
	namespaced: true,
	state: {
        user: null,
		token: null,
  	},
	mutations: {
		SET_USER(state, user){
			state.user = user
		},
		SET_TOKEN(state, token) {
            state.token = token;
        },
	},
	actions: {
        async AUTH_REQUEST({ commit, dispatch }, payload) {
            try {
                const { data } = await axios.post(`https://new.interval.spb.ru/wp-json/jwt-auth/v1/token`, payload)
                return dispatch('VALIDATE', data)
            }
            catch (err) {
                return err
            }
        },
        async VALIDATE({ commit, state, dispatch }, user) {
            if (user) {
                commit("SET_TOKEN", user.token);
                commit("SET_USER", user);
            }
            if (!state.user) {
                return
            }
            try {
                const response = await axios({
                    url: `https://new.interval.spb.ru/wp-json/jwt-auth/v1/token/validate`,
                    method: 'post',
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });

                localStorage.setItem("user", JSON.stringify(user));
                commit("SET_TOKEN", user.token);
                commit("SET_USER", user);
            }
            catch (err) {
                localStorage.removeItem("user");
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
            }
        },
        async SIGN_OUT({ commit }) {
            localStorage.removeItem("user");
            commit("SET_TOKEN", null);
            commit("SET_USER", null);
        },
        // async SIGN_UP({commit}, payload){
            
        //     try {
        //         const { data } = await axios.post(`https://sflora.ru/wp-json/sf/v1/add/user`, payload)
                
        //         if(data.status === 'Вы уже зарегистрированы'){
        //         	return data.status
        //         }
                	
        //     }
        //     catch (err) {
        //     	alert('Произошла ошибка')
        //     }
        // },
	},
	getters: {
		getAuthenticated(state){
            return state.user
        }
	}
}

export default auth


