<template>
  <section id="submit">
    <div class="container">
      <div class="col-lg-8 offset-lg-2 text-center">
        <h1 class="wow fadeInUp">
          {{ lang === 'ru' ? 'Свяжитесь с нами' : 'Contact us' }}
        </h1>
        <form
          @submit.prevent="submitForm"
          class="wow fadeIn"
          data-wow-delay=".4s"
        >
          <p
            class="big-white mb-30"
            v-if="lang === 'ru'"
          >
            Наши менеджеры ответят <br> Вам в течение <span class="orange">15
              минут</span>
          </p>
          <p
            class="big-white mb-30"
            v-else
          >
            Our managers will respond to you <br> within <span class="orange">15 minutes</span>
          </p>
          <div class="row">
            <div class="col-lg-6">
              <input
                type="text"
                :placeholder="lang === 'ru' ? 'Имя' : 'Name'"
                v-model="fio"
                :class="{errorInp :$v.fio.$dirty && !$v.fio.required}"
              >
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                :placeholder="lang === 'ru' ? 'Должность' : 'Position'"
                v-model="position"
              >
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                placeholder="E-mail"
                v-model="email" 
                :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
              >
            </div>
            <div class="col-lg-6">
              <input
                type="text"
                :placeholder="lang === 'ru' ? 'Телефон' : 'Phone'"
                v-model="phone"
                :class="{errorInp :$v.phone.$dirty && !$v.phone.required}"
              >
            </div>
            <div class="col-lg-12">
              :
              <textarea
                :placeholder="lang === 'ru' ? 'Текст вопроса/сообщения' : 'The text of the question/appeal'"
                rows="5"
              />
              <p
                class="small-gray"
                v-if="lang === 'ru'"
              >
                Нажимая "Отправить", вы соглашаетесь с  <a
                  href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                  class="orange"
                  target="_blank"
                >Политикой конфиденциальности сайта</a>
              </p>
              <p
                class="small-gray"
                v-else
              >
                By clicking "Submit", you agree with the <a
                  href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                  class="orange"
                  target="_blank"
                >Website Privacy Policy</a>
              </p>
              <br>
              <loader v-if="loading" />
              <button
                class="main-btn"
                v-else
              >
                {{ lang === 'ru' ? 'Отправить' : 'Submit' }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import formHome from '@/mixins/formHome'

export default {
    mixins: [formHome],
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    }
}
</script>