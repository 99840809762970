<template>
  <section id="usual">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tnved-grey-box">
            <div
              class="razdel"
              v-for="(item, index) in tnved"
              :key="index"
            >
              <router-link
                tag="div"
                :to="`/uslugi/poisk-po-tnved/code/${item.CODE}`"
                class="tnved-row"
                v-if="item.CODE.length === 10"
              >
                <div
                  class="razdel-code"
                  v-if="item.CODE.length === 2"
                >
                  {{ item.CODE }}
                </div>
                <h4 v-else>
                  <strong>{{ item.CODE }}</strong>
                </h4>
                <p class="black-txt">
                  {{ item.TEXT }}
                </p>
              </router-link>

              <router-link
                tag="div"
                :to="`/uslugi/poisk-po-tnved/${openRazdel(item.ID)}`"
                class="tnved-row"
                v-else
              >
                <div
                  class="razdel-code"
                  v-if="item.CODE.length === 2"
                >
                  {{ item.CODE }}
                </div>
                <h4 v-else>
                  <strong>{{ item.CODE }}</strong>
                </h4>
                <p class="black-txt">
                  {{ item.TEXT }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
    props: ['id'],
    data(){
        return {
            tnved: []
        }
    },
    watch: {
        id(){
            this.tnved = []
            this.getTnved()
        }
    },
    methods: {
        getTnved(){
            axios
            .get(`https://api1.tks.ru/tree.json/json/f0a574d9805d417c88045fb5e478017f/${this.id}.json`)
            .then(res =>{
                this.tnved = res.data
            })
        },
        openRazdel(id){
            const linkId = '0'.repeat(8 - id.toString().length) + id
            return linkId
        },
    },
    created(){
        this.getTnved();
    }
}
</script>

