<template>
  <section id="usual">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="tnved-grey-box">
            <div
              class="razdel mb-30"
              v-for="(item, index) in tnved"
              :key="index"
            >
              <router-link
                tag="a"
                :to="`/uslugi/poisk-po-tnved/${openRazdel(item.ID)}`"
              >
                <h3><span class="mdi mdi-chevron-right" />{{ item.TEXT }}</h3>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
    data() {
       return {
           tnved: [] 
       }
    },
    methods: {
        openRazdel(id){
            const linkId = '0'.repeat(8 - id.toString().length) + id
            return linkId
            // axios
            // .get(`https://api1.tks.ru/tree.json/json/7a4fe2e5a71441679d1eed2516e5b50f/${newNulls + id}.json`)
            // .then(res =>{
            //     this.tnved = res.data
            // })
        },
    },
    created(){
        axios
        .get('https://api1.tks.ru/tree.json/json/f0a574d9805d417c88045fb5e478017f/00000010.json')
        .then(res =>{
            this.tnved = res.data
        })
    }
}
</script>

<style scoped>
h3{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all .3s ease;
}
h3:hover{
    opacity: .6;
}
</style>