<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <router-link
                tag="a"
                to="/uslugi"
              >
                {{ lang === 'ru' ? 'Услуги' : 'Services' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ lang === 'ru' ? 'Международная перевозка' : 'International transportation' }}</span>
            </div>
            <h2>{{ lang === 'ru' ? 'Международная перевозка' : 'International transportation' }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="usual">
      <div class="container">
        <div class="row mb-50">
          <div class="col-lg-2">
            <img
              src="../assets/img/icons/ic7.svg"
              class="w-100 icon1 mb-30"
            >
          </div>
          <div class="col-lg-9 offset-lg-1 mb-30">
            <!-- <img :src="single(id).ic1" class="icon1 mb-30"> -->
            <p class="big-black">
              {{ lang === 'ru' ? 'На разных этапах доставки INTERVAL оптимизируем маршруты перевозок и обеспечиваем Вашему грузу подходящие условия транспортировки, используя автомобильный, авиа, железнодорожный и морской транспорт. Мультимодальные перевозки позволяют осуществлять оптимально выгодные маршруты для Ваших отправлений, в которых задействованы несколько и более видов транспорта, что позволяет уменьшить сроки и стоимость доставки.' : 'At different stages of INTERVAL delivery, we optimize transportation routes and provide your cargo with suitable transportation conditions using road, air, rail and sea transport. Multimodal transportation allows you to carry out optimally profitable routes for your shipments, which involve several or more modes of transport, which reduces the time and cost of delivery.' }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="usual">
      <div
        class="about-img-side"
        style="background-image:url('https://new.interval.spb.ru/wp-content/uploads/2023/07/avto-scaled.jpg');"
      />
      <div class="container">
        <div class="row road">
          <div class="col-lg-6" />
          <div
            class="col-lg-6 px-4"
            v-if="lang === 'ru'"
          >
            <h3 class="dark-h3">
              Автомобильные перевозки
            </h3>
            <p class="black-txt">
              Доставка автотранспортом считается одним из самых востребованных видов перевозки. Если Вам
              необходимы оптимальные сроки доставки груза, то данный вид перевозки подходит лучше всего.
              Компания INTERVAL предлагаем сервис перевозки отдельными машинами любого объема от
              поставщика в Европе до получателя в РФ. Также наша компания имеет склады консолидации по
              всему миру, которые позволяют нам осуществлять регулярные отправки сборных грузов. <br><br>
              Для наших клиентов мы всегда стараемся оптимизировать расходы по подборам груза в Европе,
              доставляя их на самый ближний склад консолидации в Финляндии, Латвии, Литве или Германии.
              Одно из самых главных преимуществ нашей компании - мы предлагаем авто доставку от 1 кг. <br><br>
              В логистику при авто-доставке входит забор груза у поставщика и доставка на склад консолидации
              согласно Ваших условий поставки, терминальная обработка и маркировка груза при
              необходимости, доставка до СВХ в СПб, прохождение таможенной очистки, вывоз на наш склад
              сортировки в Санкт-Петербурге или до склада клиента в РФ. <br><br>
              Обращаясь в нашу компанию, Вы получаете максимально выгодное предложение, конкурентные
              сроки и качественный сервис.
            </p>
          </div>
          <div
            class="col-lg-6 px-4"
            v-else
          >
            <h3 class="dark-h3">
              Road transportation
            </h3>
            <p class="black-txt">
              Delivery by road is considered one of the most popular types of transportation. If you need optimal
              delivery times, then this type of transportation is best suited. INTERVAL company offers a service of
              transportation by individual machines of any volume from the supplier in Europe to the recipient in the
              Russian Federation. Our company also has consolidation warehouses all over the world, which allow us
              to conduct regular shipments of bulk cargoes. <br><br>
              For our clients, we always try to optimize the costs of picking up cargo in Europe by delivering them to
              the nearest consolidation warehouse in Finland, Latvia, Lithuania or Germany. One of the main
              advantages of our company is that we offer auto delivery from 1 kg. <br><br>
              The logistics of auto delivery includes the collection of cargo from the supplier and delivery to the
              consolidation warehouse according to your delivery conditions, terminal processing and labeling of
              cargo, if necessary, delivery to the temporary storage warehouse in St. Petersburg, customs clearance,
              export to our warehouse.<br><br>
              By contacting our company, you get the most favorable offer, competitive
              terms and high-quality service.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      id="usual"
      style="background-color:#ECECEC;"
      class="air"
    >
      <div
        class="about-img-side right-side"
        style="background-image:url('https://new.interval.spb.ru/wp-content/uploads/2023/07/avia-scaled.jpg');"
      />
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 px-4"
            v-if="lang === 'ru'"
          >
            <h3 class="dark-h3">
              Авиаперевозки
            </h3>
            <p class="black-txt">
              Ввиду все большего развития международных отношений одной из основных составляющих
              внешнеэкономической деятельности для компаний является осуществление международной
              грузоперевозки. Цены на авиаперевозки грузов из-за рубежа, конечно же, отличаются от
              стоимости морской или автомобильной транспортировки. Однако с помощью авиаперевозок
              возможно без проблем и в сжатые сроки доставить груз практически из любой точки мира.<br><br>
              Стоимость международных грузоперевозок самолетом в первую очередь зависит от
              характеристик груза, а также пункта отправления, оценочной стоимости, условий поставки и пр.
              Конечная стоимость доставки груза в Россию определяется сотрудником нашей компании после
              предоставления всех необходимых документов.<br><br>
              Наши специалисты всегда проконсультируют Вас по всем возникающим вопросам и просчитают
              окончательную стоимость авиаперевозки Вашего груза с учётом всех сопутствующих услуг и
              сборов.
            </p>
          </div>
          <div
            class="col-lg-6 px-4"
            v-else
          >
            <h3 class="dark-h3">
              Air transportation
            </h3>
            <p class="black-txt">
              Due to the increasing development of international relations, one of the main components of foreign
              economic activity for companies is the implementation of international cargo transportation. The prices
              for air cargo transportation from abroad, of course, differ from the cost of sea or road transportation.
              However, with the help of air transportation, it is possible to deliver cargo from almost anywhere in the
              world without problems and in a short time. <br><br>
              The cost of international cargo transportation by plane primarily depends on the characteristics of the
              cargo, as well as the point of departure, estimated cost, delivery conditions, etc. The final cost of cargo
              delivery to Russia is determined by an employee of our company after providing all the necessary
              documents. <br><br>
              Our specialists will always advise you on any questions that arise and calculate the final cost of air
              transportation of your cargo, taking into account all related services and fees.
            </p>
          </div>
          <div class="col-lg-6" />
        </div>
      </div>
    </section>

    <section
      id="usual"
      class="rail"
    >
      <div
        class="about-img-side"
        style="background-image:url('https://new.interval.spb.ru/wp-content/uploads/2023/07/zhd-scaled.jpg');"
      />
      <div class="container">
        <div class="row">
          <div class="col-lg-6" />
          <div
            class="col-lg-6 px-4"
            v-if="lang === 'ru'"
          >
            <h3 class="dark-h3">
              Железнодорожные перевозки
            </h3>
            <p class="black-txt">
              Доставка железнодорожным видом транспорта - высокая надежность при доступных ценах,
              разветвленная сеть отправлений на дальние расстояния с четким временным графиком.
              Железнодорожные перевозки могут быть как самостоятельной услугой, так и частью
              мультимодальных схем доставки. <br><br>
              Исходя из особенностей груза и географии, а также ваших приоритетов по стоимости и срокам,
              специалисты INTERVAL подберут для вас наилучший вариант железнодорожной перевозки. Мы
              найдем оптимальные маршруты и тарифы, заранее проконсультируем Вас по всем нюансам
              перевозки, подпишем договор об оказании услуг.<br><br>
              В стоимость услуги входит:<br>
              - подбор и букирование поезда и вагона, контейнера и т.д. в зависимости от вида вашего груза;<br>
              - погрузочно-разгрузочные работы, в том числе сложных и негабаритных грузов.<br><br>
              Транспортировка по железной дороге гарантирует почти стопроцентную сохранность груза,
              практически нет рисков повреждения продукции из-за плохого дорожного покрытия и аварийных
              ситуаций.
            </p>
          </div>
          <div
            class="col-lg-6 px-4"
            v-else
          >
            <h3 class="dark-h3">
              Railway transportation
            </h3>
            <p class="black-txt">
              Delivery by rail means of transport - high reliability at affordable prices, an extensive network of long-
              distance shipments with a clear time schedule.<br><br>
              Rail transportation can be both an independent service and part of multimodal delivery schemes.
              Based on the characteristics of the cargo and geography, as well as your priorities in terms of cost and
              timing, INTERVAL specialists will select the best option for you by rail. We will find the best routes and
              fares, advise you in advance on all the nuances of transportation, and sign a contract for the provision of
              services.<br><br>
              The cost of the service includes:<br>
              - selection and booking of trains and wagons, containers, etc. depending on the type of your cargo;<br>
              - loading and unloading operations, including complex and oversized cargo.<br><br>
              Transportation by rail guarantees almost one hundred percent safety of cargo, there is practically no risk
              of damage to products due to poor road surface and emergency situations.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      id="usual"
      style="background-color:#ECECEC;"
      class="sea"
    >
      <div
        class="about-img-side right-side"
        style="background-image:url('https://new.interval.spb.ru/wp-content/uploads/2023/07/morskie-scaled.jpg');"
      />
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 px-4"
            v-if="lang === 'ru'"
          >
            <h3 class="dark-h3">
              Морские перевозки
            </h3>
            <p class="black-txt">
              Компания INTERVAL осуществляет перевозку морским видом транспорта с любых портов мира.
              Самые востребованные на сегодняшний день - контейнерные перевозки грузов по схемам LCL и
              FCL. <br><br>
              Если Вы только начинаете свой бизнес и Вам необходимо привезти небольшую и недорогую
              партию товара, то мы организуем для Вас сборную доставку товара от 50 кг. При доставке
              генеральных грузов, забукуем как 20 футовый, так и 40 футовый контейнер, а также другие виды
              контейнеров.<br><br>
              Перевозка морским транспортом считается одним из самых экономичных вариантов доставки.
              Мы организуем мультимодальные схемы из Америки, Китая, используя наши склады
              консолидации в Европе для дополнительных операций или напрямую в порты РФ.<br><br>
              Миссия компании – доставка «от двери до двери», «под ключ». Готовы подать контейнер под
              погрузку или забрать сборный груз у отправителя, забуковаться на судне, произвести все
              необходимые операции в порту отправления и назначения, произвести таможенную очистку,
              сделать внутрипортовое экспедирование и доставить Ваш груз в любой регион РФ.<br><br>
              Для получения ставки на перевозку Вы можете оставить онлайн заказ на нашем сайте или
              получить консультацию у нашего специалиста.<br><br>
              Мы всегда думаем об оптимизации расходов и подбираем нашим клиентам самые выгодные
              условия.
            </p>
          </div>
          <div
            class="col-lg-6 px-4"
            v-else
          >
            <h3 class="dark-h3">
              Sea transportation
            </h3>
            <p class="black-txt">
              INTERVAL company carries out transportation by sea from any ports of the world. The most popular
              today is container cargo transportation according to LCL and FCL schemes.<br><br>
              If you are just starting your business and you need to bring a small and inexpensive batch of goods, then
              we will arrange for you a combined delivery of goods from 50 kg. When delivering general cargo, we will
              pack both 20-foot and 40-foot containers, as well as other types of containers.<br><br>
              Shipping by sea is considered one of the most economical delivery options. We organize multimodal
              schemes from America, China, using our consolidation warehouses in Europe for additional operations
              or directly to the ports of the Russian Federation.<br><br>
              The company's mission is door–to-door, turnkey delivery. We are ready to submit a container for
              loading or pick up a combined cargo from the sender, get stuck on the ship, perform all necessary
              operations at the port of departure and destination, perform customs clearance, do intraport
              forwarding and deliver your cargo to any region of the Russian Federation.<br><br>
              To get a bid for transportation, you can leave an online order on our website or get advice from our
              specialist.<br><br>
              We always think about cost optimization and select the most favorable conditions for our clients.
            </p>
          </div>
          <div class="col-lg-6" />
        </div>
      </div>
    </section>


    <section
      id="usual"
      class="consolidated"
    >
      <div
        class="about-img-side"
        style="background-image:url('https://new.interval.spb.ru/wp-content/uploads/2023/07/sbornye-scaled.jpg');"
      />
      <div class="container">
        <div class="row">
          <div class="col-lg-6 " />
          <div
            class="col-lg-6 px-4"
            v-if="lang === 'ru'"
          >
            <h3 class="dark-h3">
              Сборные грузы
            </h3>
            <p class="black-txt">
              Доставка сборных грузов от 1 кг из Европы, Китая и США в РФ. Мы организуем перевозку,
              таможенное оформление, консолидацию мелкогабаритных грузов различных заказчиков в одном
              направлении, на одном транспортном средстве по меньшему тарифу доставки. Стараемся оптимизировать расходы по подборам сборных грузов в Европе, доставляя их на самый
              ближайший склад консолидации в Финляндии, Латвии, Литве или Германии.<br><br>
              INTERVAL имеет отлаженную маршрутную международную схему, что позволяет быстро
              отправлять товары из любой точки мира получателям в разные регионы РФ.<br><br>
              Обеспечиваем Вашему бизнесу преимущества в сроках и надежности.
            </p>
          </div>
          <div
            class="col-lg-6 px-4"
            v-else
          >
            <h3 class="dark-h3">
              Combined cargoes
            </h3>
            <p class="black-txt">
              Delivery of combined cargoes from 1 kg from Europe, China and the USA to the Russian Federation. We
              organize transportation, customs clearance, consolidation of small-sized cargoes of various customers in
              one direction, on one vehicle at a lower delivery rate. We try to optimize the costs of picking up bulk
              cargoes in Europe by delivering them to the nearest consolidation warehouse in Finland, Latvia,
              Lithuania or Germany. <br><br>
              INTERVAL has a well-established international route scheme, which allows you to quickly send goods
              from anywhere in the world to recipients in different regions of the Russian Federation.<br><br>
              We provide your business with advantages in terms of timing and reliability.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      id="usual"
      style="background-color:#ECECEC;"
      class="express"
    >
      <div
        class="about-img-side right-side"
        style="background-image:url('https://new.interval.spb.ru/wp-content/uploads/2023/07/ekspress-dostavka-scaled.jpg');"
      />
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 px-4"
            v-if="lang === 'ru'"
          >
            <h3 class="dark-h3">
              Экспресс-доставка
            </h3>
            <p class="black-txt">
              Работаем с ведущими мировыми службами курьерского сервиса - UPS, TNT, DHL и предлагаем
              выгодное обслуживание авиаперевозок. Это обеспечивает быстрые сроки доставки сборных
              грузов от 3-х дней и экспресс-перевозку срочного груза наших клиентов. <br><br>
              Для нашей компании важна скорость грузоперевозки, высокое качество обслуживания, поэтому
              мы прикладываем максимум усилий в решении подобных запросов.
            </p>
          </div>
          <div
            class="col-lg-6 px-4"
            v-else
          >
            <h3 class="dark-h3">
              Express delivery
            </h3>
            <p class="black-txt">
              We work with the world's leading courier services - UPS, TNT, DHL and offer profitable air transportation
              service. This ensures fast delivery times of combined cargoes from 3 days and express transportation of
              urgent cargo of our customers. <br><br>
              The speed of cargo transportation and high quality of service are important for our company, so we
              make every effort to solve such requests.
            </p>
          </div>
          <div class="col-lg-6" />
        </div>
      </div>
    </section>
    

    <section id="order">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="dark-h3 mb-10">
              {{ lang === 'ru' ? 'Заказать услугу' : 'Order service' }}
            </h3>
            <p class="black-txt mb-30">
              {{ lang === 'ru' ? 'Наши менеджеры быстро ответят на заявку' : 'Our managers will quickly respond to the application' }}
            </p>
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <form
                  @submit.prevent="submitForm"
                  class="order-form"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'ФИО' : 'Name'"
                        v-model="fio"
                        :class="{errorInp :$v.fio.$dirty && !$v.fio.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'Телефон' : 'Phone'"
                        v-model="phone"
                        :class="{errorInp :$v.phone.$dirty && !$v.phone.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        placeholder="E-mail"
                        v-model="email" 
                        :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
                      >
                    </div>
                    <div class="col-lg-12">
                      <input
                        type="text"
                        :value="getServVal(lang === 'ru' ? 'Услуга: Международная перевозка' : 'Service: International transportation')"
                        disabled
                      >
                    </div>
                    <div class="col-lg-12">
                      <textarea
                        :placeholder="lang === 'ru' ? 'Комментарий' : 'Comment'"
                        rows="5"
                        v-model="comment"
                      />
                    </div>
                    <div class="col-lg-12 text-center">
                      <p
                        class="small-gray"
                        v-if="lang === 'ru'"
                      >
                        Нажимая "Отправить", вы соглашаетесь с  <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                          class="orange"
                        >Политикой конфиденциальности сайта</a>
                      </p>
                      <p
                        class="small-gray"
                        v-else
                      >
                        By clicking "Submit", you agree with the <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/website-privacy-policy-and-personal-data-processing-interval.pdf"
                          class="orange"
                        >Website Privacy Policy</a>
                      </p>
                      <br>
                      <loader v-if="loading" />
                      <button
                        class="main-btn"
                        v-else
                      >
                        {{ lang === 'ru' ? 'Отправить' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formGlobal from '@/mixins/formGlobal'

export default {
    mixins: [formGlobal],
    computed: {
        ...mapGetters({
            sales: "sales/getSales",
            lang: "lang/getLang",
        })
    },
    data(){
       return{
           services: [
                {
                    slug: 'ved-agent',
                    name: 'ВЭД-Агент',
                    txt: 'Расчет за 2 часа',
                    ic1: require('../assets/img/icons/ic3.svg'),
                    ic2: require('../assets/img/icons/ic3w.svg'),
                    col: 3
                },
                {
                    slug: 'podbor',
                    name: 'Подбор товара за рубежом',
                    txt: 'Расчет за 2 часа',
                    ic1: require('../assets/img/icons/ic4.svg'),
                    ic2: require('../assets/img/icons/ic4w.svg'),
                    col: 6
                },
                {
                    slug: 'poisk-po-artikulu',
                    name: 'Поиск информации по артикулу',
                    txt: 'Расчет за 2 часа',
                    ic1: require('../assets/img/icons/ic2.svg'),
                    ic2: require('../assets/img/icons/ic2w.svg'),
                    col: 3
                },
           ]
       }
    },
    mounted(){
            if(this.$route.hash !== ''){
                this.goScroll()
            }
    },
   watch: {
        $route(to, from) {
            // let options = {
            //         offset: -130,
            //         force: true
            //     }
            // this.$scrollTo(`.${this.$route.hash.replace(/#/g, '')}`, 200, options)
            $('html, body').animate({scrollTop: $(`.${this.$route.hash.replace(/#/g, '')}`).offset().top -130 }, 'slow')
        }
    },
    methods: {
        openAccordeon(i){
            this.faq[i].active = !this.faq[i].active
        },
        goScroll(){
            this.$nextTick(() => {
                // let options = {
                //     offset: -65,
                //     force: true
                // }
                // this.$scrollTo(`.${this.$route.hash.replace(/#/g, '')}`, 200, options)
                $('html, body').animate({scrollTop: $(`.${this.$route.hash.replace(/#/g, '')}`).offset().top  -130 }, 'slow')
            })
        }
    },
}
</script>

<style scoped>
.home::before{
    display: none;
}
#usual{
    position: relative;
}
</style>