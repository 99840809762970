import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import YmapPlugin from 'vue-yandex-maps'
import VueScrollactive from 'vue-scrollactive';
// import VueScrollTo from 'vue-scrollto'
import TextHighlight from 'vue-text-highlight';
import Vuelidate from 'vuelidate'
import Vuetify from 'vuetify'
import vClickOutside from 'v-click-outside'

Vue.use(Vuetify)
import ru from 'vuetify/es5/locale/ru'


Vue.config.productionTip = false


const settings = {
  apiKey: '8c4059db-3b8d-4535-a15e-569ee80fc827',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
}

const user = JSON.parse(localStorage.getItem("user"))
store.dispatch("auth/VALIDATE", user);

Vue.use(YmapPlugin, settings)
Vue.use(VueScrollactive);
Vue.component('text-highlight', TextHighlight);
// Vue.use(VueScrollTo)
Vue.use(vClickOutside)
Vue.use(Vuelidate)
new Vue({
  router,
  store,
  vuetify: new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru'
    }
  }),
  render: h => h(App)
}).$mount('#app')
