<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="mb-0">
              {{ lang === 'ru' ? 'Личный кабинет' : 'Personal account' }}
            </h2>
            <div class="breadcrums">
              <span>ООО "Нефтегазтрансалмаз"</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="profile">
      <div class="container-fluid">
        <div class="row">
          <div class="sidebar col-12 col-lg-1">
            <ul>
              <router-link
                tag="li"
                to="/profile"
              >
                Главная
              </router-link>
              <router-link
                tag="li"
                to="/profile/stat"
              >
                Статистика
              </router-link>
              <router-link
                tag="li"
                to="/profile/logistics"
              >
                Логистика
              </router-link>
              <li @click="signOut">
                Выйти
              </li>
            </ul>
          </div>
          <div class="col-lg-10">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    methods: {
        signOut(){
            this.$store.dispatch('auth/SIGN_OUT').then(() => {
        		this.$router.replace("/enter");
			});
        }
    }
}
</script>

<style scoped>
.home::before {
    display: none ;
}
</style>