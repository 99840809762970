<template>
  <div class="home">
    <section
      id="inner"
      :style="`background-image:url(${img});`"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }}<span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ lang === 'ru' ? 'Услуги' : 'Our services' }}</span>
            </div>
            <h2>{{ lang === 'ru' ? 'Услуги' : 'Our services' }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="usual">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="dark-h3 mb-30">
              {{ lang === 'ru' ? 'Перечень наших услуг' : 'Our services' }}
            </h3>
          </div>
          <div
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in services"
            :key="i"
          >
            <router-link
              tag="div"
              :to="`/uslugi/${item.slug}`"
              class="serv-box"
            >
              <img
                :src="item.ic1"
                class="icon1"
              >
              <img
                :src="item.ic2"
                class="icon2"
              >
              <h4>{{ item.name[lang] }}</h4>
              <p class="black-txt">
                {{ item.txt }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            img: 'https://new.interval.spb.ru/wp-content/uploads/2023/07/glavnaya-vo-vseh-razdelah-scaled.jpg'
        }
    },
    computed: {
        ...mapGetters({
            services: "serv/getServ",
            lang: "lang/getLang"
        })
    },
    methods: {
        openAccordeon(i){
            this.faq[i].active = !this.faq[i].active
        }
    }
}
</script>

<style scoped>
.home::before{
    display: none;
}
</style>