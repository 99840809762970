<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <router-link
                tag="a"
                to="/uslugi"
              >
                {{ lang === 'ru' ? 'Услуги' : 'Services' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ lang === 'ru' ? 'Примеры декларирования' : 'Examples of declaration' }}</span>
            </div>
            <h2>{{ lang === 'ru' ? 'Примеры декларирования' : 'Examples of declaration' }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="usual">
      <div class="container">
        <div
          class="row"
          v-if="!load"
        >
          <div class="col-lg-12">
            <h4 class="dark-h3 mb-50 gray">
              {{ lang === 'ru' ? 'Результаты' : 'Results' }}: <span class="orange">{{ items.length }}</span>
            </h4>
            <div class="row">    
              <div
                class="col-lg-12"
                v-for="(item, index) in items"
                :key="index"
              >
                <!-- <pre>{{item}}</pre> -->
                <div class="article-row mb-15">
                  <h4>{{ item.artikul }} </h4>
                  <p
                    class="black-txt"
                    v-html="' - ' + item.name"
                  />
                </div>
                <div class="row">
                  <div class="col-lg-2">
                    <p class="small-gray">
                      {{ lang === 'ru' ? 'Код ТН ВЭД' : 'Код ТН ВЭД' }}:
                    </p>
                    <p class="black-txt">
                      {{ item.tnved }}
                    </p>
                  </div>
                  <div class="col-lg-3">
                    <p class="small-gray">
                      {{ lang === 'ru' ? 'Производитель' : 'Brand' }}:
                    </p>
                    <p class="black-txt">
                      {{ item.proizvoditel }}
                    </p>
                  </div>
                  <div class="col-lg-2">
                    <p class="small-gray">
                      {{ lang === 'ru' ? 'Страна происхождения' : 'Origin country' }}:
                    </p>
                    <p class="black-txt">
                      {{ item.strana_proizvoditel }}
                    </p>
                  </div>
                  <div class="col-lg-7 my-4">
                    <p class="small-gray">
                      {{ lang === 'ru' ? 'Описание' : 'Description' }}:
                    </p>
                    <p class="black-txt">
                      {{ item.opisanie_3 }}
                    </p>
                  </div>
                </div>
                <ul class="markers">
                  <li @click="formSelect(1)">
                    {{ lang === 'ru' ? 'Получить консультацию по товару ' : 'Get product advice' }}
                  </li>
                  <!-- <li @click="formSelect(2)">{{lang === 'ru' ? 'Доставка' : 'Оформление'}}</li> -->
                </ul>
                <hr>
              </div>
            </div>
          </div>
        </div>
        <loader v-else />
      </div>
    </section> 
    <section
      id="order"
      v-if="form_selected"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="dark-h3 mb-10">
              {{ lang === 'ru' ? 'Заказать услугу' : 'Order service' }}
            </h3>
            <p class="black-txt mb-30">
              {{ lang === 'ru' ? 'Наши менеджеры быстро ответят на заявку' : 'Our managers will quickly respond to the application' }}
            </p>
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <form
                  action=""
                  class="order-form"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'ФИО' : 'Name'"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'Телефон' : 'Phone'"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        placeholder="E-mail"
                      >
                    </div>
                    <div
                      class="col-lg-12"
                      v-if="form_selected === 1"
                    >
                      <input
                        type="text"
                        :value="getServVal(lang === 'ru' ? 'Услуга: Получить консультацию по товару ' : 'Service: Get product advice')"
                        disabled
                      >
                    </div>
                    <div
                      class="col-lg-12"
                      v-if="form_selected === 2"
                    >
                      <input
                        type="text"
                        :value="`${lang === 'ru' ? 'Услуга: Доставка и таможенное оформление ' : 'Service: Подробнее о наличии товара'}`"
                        disabled
                      >
                    </div>
                    <div class="col-lg-12">
                      <textarea
                        :placeholder="lang === 'ru' ? 'Комментарий' : 'Comment'"
                        rows="5"
                      />
                    </div>
                    <div class="col-lg-12 text-center">
                      <p
                        class="small-gray"
                        v-if="lang === 'ru'"
                      >
                        Нажимая "Отправить", вы соглашаетесь с  <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                          class="orange"
                        >Политикой конфиденциальности сайта</a>
                      </p>
                      <p
                        class="small-gray"
                        v-else
                      >
                        By clicking "Submit", you agree with the <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/website-privacy-policy-and-personal-data-processing-interval.pdf"
                          class="orange"
                        >Website Privacy Policy</a>
                      </p>
                      <br>
                      <button class="main-btn">
                        {{ lang === 'ru' ? 'Отправить' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="addition">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="dark-h3 mb-30">
              {{ lang === 'ru' ? 'Дополнительные услуги' : 'Additional services' }}
            </h3>
          </div>
          <div
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in services"
            :key="i"
          >
            <router-link
              tag="div"
              :to="`/uslugi/${item.slug}`"
              class="serv-box"
            >
              <img
                :src="item.ic1"
                class="icon1"
              >
              <img
                :src="item.ic2"
                class="icon2"
              >
              <h4>{{ item.name[lang] }}</h4>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import loader from '../components/ui/loader.vue'
import axios from 'axios'
import formGlobal from '@/mixins/formGlobal'

export default {
    components: {loader},
    mixins: [formGlobal],
    computed: {
        ...mapGetters({
            articleSearch: "serv/getArticleSearch",
            lang: "lang/getLang",
        })
    },
    watch: {
        articleSearch(){
            if(this.articleSearch.length){
                this.items = this.articleSearch
                this.load = false
            }
        }
    },
    methods: {
        filterBySearch(){    
            if(this.search === ''){
                this.items = this.articleSearch
            }
            if(this.search.length < 3){
                return
            }
            const num = this.search?.match(/\d+/g)
            const letr = this.search?.match(/[а-яA-Я]+/g)

            if (letr != null && num == null) {
                this.url = `https://new.interval.spb.ru/wp-json/int/v1/get/articles?text=${letr.join(' ')}`
            }else if (num != null && letr == null) {
                this.url = `https://new.interval.spb.ru/wp-json/int/v1/get/articles?article=${num.join('')}`
            }else{
                this.url = `https://new.interval.spb.ru/wp-json/int/v1/get/articles?text=${letr.join(' ')}&article=${num.join('')}`
            }

            this.load = true
            axios
            .get(this.url)
            .then(res =>{
                this.load = false
                this.items = res.data
            })
        },
        formSelect(i){
            this.form_selected = i
            this.$nextTick(() => {
                // let options = {
                //     offset: 0,
                //     force: true
                // }
                // this.$scrollTo(`#order`, 200, options)
                $('html, body').animate({scrollTop: $('#order').offset().top }, 'slow')
            })
        }
    },
    created(){
        this.load = true
        if(this.$route.query?.code){
          this.$store.dispatch("serv/loadArticleSearch", this.$route.query.code).then(() =>{
            this.load = false
          })
        }else{
          this.$store.dispatch("serv/loadArticleSearch").then(() =>{
              this.load = false
          })
        }
    },
    data(){
       return{
            items: [],
            search: '',
            load: false,
            url: '',
            form_selected: null,
            services: [
                {
                    slug: 'mezhdunarodnaya',
                    name: {en: 'International transportation', ru: 'Международная перевозка'},
                    ic1: require('../assets/img/icons/ic7.svg'),
                    ic2: require('../assets/img/icons/ic7w.svg'),
                    col: 3
                },
                {
                    slug: 'tnved',
                    name: {en: 'Search by the Customs Code of the EAEU (HS)', ru: 'Поиск информации по ТН ВЭД'},
                    ic1: require('../assets/img/icons/ic5.svg'),
                    ic2: require('../assets/img/icons/ic5w.svg'),
                    col: 6
                },
                {
                    slug: 'dostavka',
                    name: {en: 'Cargo delivery for marketplaces', ru: 'Доставка грузов для маркетплейсов'},
                    ic1: require('../assets/img/icons/ic14.svg'),
                    ic2: require('../assets/img/icons/ic14w.svg'),
                    col: 3
                },
           ]
       }
   }
}
</script>

<style scoped>
.home::before{
    display: none;
}
</style>