<template>
  <section id="sales">
    <div class="container">
      <div class="white-box">
        <div class="row">
          <div class="col-lg-12">
            <h2>{{ lang === 'ru' ? 'Наши предложения' : 'Our offers' }}</h2>
          </div>

          <div
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in sales"
            :key="i"
            class="wow fadeInUp"
          >
            <router-link
              tag="div"
              :to="`/sales/${i+1}`"
              class="sale-box"
            >
              <div>
                <h4>{{ item.name[lang] }}</h4>
                <p
                  class="white-txt"
                  v-html="item.txt[lang]"
                />
              </div>
            </router-link>
          </div>
          <div class="col-lg-12 wow fadeInUp text-center">
            <router-link
              tag="button"
              to="/sales"
              class="main-btn"
            >
              {{ lang === 'ru' ? 'Все предложения' : 'All offers' }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    data(){
        return{
            sales: [
                {
                    name: {en: 'Consolidation of goods in China from 1 kg', ru: 'Консолидация грузов в Китае от 1 кг'},
                    txt: {en: 'Air delivery from <span>500$</span>', ru:'Авиадоставка от <span>500$</span>'},
                    col: 4
                },
                {
                    name: {en: 'Consolidation of goods in Hong Kong from 1 kg', ru: 'Консолидация грузов в Гонконге от 1 кг'},
                    txt: {en: 'Air delivery from <span>500$</span>', ru:'Авиадоставка от <span>500$</span>'},
                    col: 4
                },
                {
                    name: {en: 'Consolidation of air cargo in Turkey', ru: 'Консолидация авиа грузов в Турции'},
                    txt: {en: 'With the possibility of sending to the airport of the company<span>DHL, UPS, FedEx</span>', ru:'С возможностью отправки в аэропорт компании <span>DHL, UPS, FedEx</span>'},
                    col: 4
                },
                {
                    name: {en: 'Legal entity in Turkey', ru: 'Юридическое лицо в Турции'},
                    txt: {en: 'Payment-delivery of goods according to the foreign agent scheme, service for the purchase from a supplier from the mail of a foreign counterparty', ru:'Оплата-поставка товара по схеме ВЭД-Агент, сервис по закупке у поставщика с почты иностранного контрагента'},
                    col: 4
                },
                {
                    name: {en: 'Delivery of dual-use cargo', ru: 'Доставка грузов двойного назначения'},
                    txt: {en: 'From Europe', ru:'Из Европы'},
                    col: 4
                },
                {
                    name: {en: 'Legal entity in Hong Kong', ru: 'Юридическое лицо в Гонконге'},
                    txt: {en: 'Delivery of goods according to the scheme of <span>foreign trade agent</span>', ru:'оплата-поставка товара по схеме<br> <span>ВЭД-Агент</span>'},
                    col: 4
                },
            ]
        }
    }
}
</script>

<style scoped>
.white-txt{
    min-height: 60px;
}
</style>