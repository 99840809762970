<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>{{ lang === 'ru' ? 'Личный кабинет' : 'Personal account' }}</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="login">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 offset-lg-4 text-center">
            <h3 class="black">
              {{ lang === 'ru' ? 'Регистрация' : 'Signup' }}
            </h3>
            <form @submit.prevent="login">
              <input
                type="text"
                :placeholder="lang === 'ru' ? 'Логин' : 'Login'"
                class="mb-15"
                v-model="name"
              >
              <input
                type="password"
                :placeholder="lang === 'ru' ? 'Пароль' : 'Password'"
                class="mb-15"
                v-model="pass"
              >
              <input
                type="password"
                :placeholder="lang === 'ru' ? 'Повторите пароль' : 'Repeat password'"
                class="mb-15"
                v-model="repeat"
              >
              <button
                type="submit"
                class="main-btn w-100 mb-15"
              >
                {{ lang === 'ru' ? 'Войти' : 'Login' }}
              </button>
            </form>
            <p class="black-txt">
              Уже есть аккаунт? <br><router-link
                tag="a"
                class="orange"
                to="/enter"
              >
                Войти
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            name: '',
            pass: '',
            repeat: ''
        }
    },
    // methods: {
    //     login(){
    //         let form = {
    //             username: this.name,
    //             password: this.pass,
    //         };

    //         this.$store.dispatch('auth/AUTH_REQUEST', form).then((err) => {
    //                 this.load = false
    //                 if(err){
    //                     return alert(err)
    //                 }else{
    //                     this.$router.replace("/profile");
    //                 }
    //         });
    //     }
    // },
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
}
</script>

<style scoped>
.home::before {
    display: none ;
}
</style>