<template>
  <div
    class="home home-div"
    :class="{bgWh : bgWh, hideOverlay : !videoVis}"
  >
    <transition
      name="zoom"
      mode="out-in"
    > 
      <!-- <video src="https://new.interval.spb.ru/wp-content/uploads/2022/04/mainvideo.mp4" 
	        autoplay="autoplay" 
	        loop="loop" 
	        playsinline="playsinline" 
	        :muted="true" 
	        class="bg-video hidden-xs" v-if="videoVis">
		    </video> -->
      <video
        src="https://new.interval.spb.ru/wp-content/uploads/2022/12/interval_compressed.mp4" 
        autoplay="autoplay" 
        loop="loop" 
        playsinline="playsinline" 
        :muted="true" 
        class="bg-video hidden-xs"
        v-if="videoVis"
      />
      <!-- https://new.interval.spb.ru/wp-content/uploads/2022/10/intarval.mov -->
    </transition>

    <!-- <transition name="zoom" mode="out-in"> 
        <div class="segmentBg" v-if="bgVisible"></div>
    </transition>
    <transition name="zoom" mode="out-in"> 
        <div class="submitBg" v-if="submitVisible"></div>
    </transition> -->
    
    <Main />
    <Services />
    <About />
    <Segment />
    <Sales />
    <Geo />
    <Submit />
    <Contacts />
  </div>
</template>

<script>
const basicScroll = require('basicscroll')
import Services from '../components/Services.vue'
import Main from '../components/Main.vue'
import About from '../components/About.vue'
import Segment from '../components/Segment.vue'
import Sales from '../components/Sales.vue'
import Geo from '../components/Geo.vue'
import Submit from '../components/Submit.vue'
import Contacts from '../components/Contacts.vue'

export default {
  components: {Main, Services, About, Segment, Sales, Geo, Submit,Contacts},
  data(){
    return{
      bgVisible: false,
      bgWh: false,
      videoVis: true
    }
  },
  methods: {
    goScroll(){
      this.$nextTick(() => {
        // let options = {
        //   offset: 140,
        //   force: true
        // }
        // this.$scrollTo(this.$route.hash, 200, options)
        $('html, body').animate({scrollTop: $(`${this.$route.hash}`).offset().top + 200 }, 'slow')
      })
    }
  },
  mounted(){
    if(this.$route.hash !== ''){
      this.goScroll()
    }
    // const instance4 = basicScroll.create({
		// 		elem: document.querySelector('#segment'),
		// 		from: 'middle-bottom',
		// 		to: 'bottom-middle',
    //             inside: (instance, percentage, props) => {
    //                 this.bgVisible = true
    //             },
    //             outside: (instance, percentage, props) => {
    //                  this.bgVisible = false
    //             }
		// 	})
		// instance4.start()

    const instance6 = basicScroll.create({
				elem: document.querySelector('.home-div'),
				from: 'top-top',
				to: 'bottom-bottom',
                inside: (instance, percentage, props) => {
                    this.videoVis = true
                },
                outside: (instance, percentage, props) => {
                     this.videoVis = false
                }
			})
		instance6.start()
  }
}
</script>


<style>
.zoom-leave-active{
	opacity: 0;
	animation: zoom-out .1s  ease-out forwards;
}
.zoom-leave{
	opacity: 1;
}
.zoom-enter-active{
	animation: zoom-in .1s  ease-out forwards;
	opacity: 1;
}
@keyframes zoom-out{
	0%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}
@keyframes zoom-in{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
</style>