<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                Главная <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>Наши предложения</span>
            </div>
            <h2>Наши предложения</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="usual">
      <div class="container">
        <div class="row">
          <router-link
            tag="div"
            :to="`/sales/${i+1}`"
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in sales"
            :key="i"
            class="wow fadeInUp"
          >
            <div class="sale-box">
              <div>
                <h4>{{ item.name }}</h4>
                <p
                  class="white-txt"
                  v-html="item.txt"
                />
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
    data(){
        return{
            sales: [
                {
                    name: 'Консолидация грузов в Китае от 1 кг',
                    txt: 'Авиадоставка от <span>500$</span>',
                    col: 4
                },
                {
                    name: 'Консолидация грузов в Гонконге от 1 кг',
                    txt: 'Авиадоставка от <span>500$</span>',
                    col: 4
                },
                {
                    name: 'Консолидация авиа грузов в Турции',
                    txt: 'С возможностью отправки в аэропорт компании <span>DHL, UPS, FedEx</span>',
                    col: 4
                },
                {
                    name: 'Юридическое лицо в Турции',
                    txt: 'Оплата-поставка товара по схеме ВЭД-Агент, сервис по закупке у поставщика с почты иностранного контрагента',
                    col: 4
                },
                {
                    name: 'Доставка грузов двойного назначения',
                    txt: 'Из Европы',
                    col: 4
                },
                {
                    name: 'Юридическое лицо в Гонконге',
                    txt: 'оплата-поставка товара по схеме<br> <span>ВЭД-Агент</span>',
                    col: 4
                },
            ]
        }
    }
}
</script>

<style scoped>
.home::before {
    display: none;
}
.sale-box .white-txt{
    min-height: 60px;
}
</style>