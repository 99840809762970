<template>
  <section class="p-4">
    <h4 class="mb-2">
      Логистика
    </h4>
    <div class="row">
      <div class="col-lg-3 mb-4">
        <label class="mb-2">Введите трек номер</label>
        <input type="text">
      </div>
      <div class="col-lg-12">
        <div class="timeline">
          <div
            class="timeline-item"
            v-for="(item, id) in tracking"
            :key="id"
          >
            <div class="timeline-dot" />
            <div>
              <p class="big-black mb-5">
                Получено адресатом
              </p>
              <p class="small-gray mb-0">
                30 мая 2022, 14:27 188308, Гатчина
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    data(){
        return{
            tracking: [
               {
                   name: 'Получено адресатом',
                   txt: '30 мая 2022, 14:27 188308, Гатчина'
               },
               {
                   name: 'Прибыло в место вручения',
                   txt: '28 мая 2022, 09:30 188308, Гатчина'
               },
               {
                   name: 'Покинуло сортировочный центр',
                   txt: '28 мая 2022, 03:59 200960, Санкт-Петербург'
               },
               {
                   name: 'Сортировка',
                   txt: '26 мая 2022, 12:53 141816, Дмитров'
               },
               {
                   name: 'Прибыло в сортировочный центр',
                   txt: '28 мая 2022, 01:41 200983, Санкт-Петербург'
               },
               {
                   name: 'Покинуло место приема',
                   txt: '25 мая 2022, 19:09 141865, Некрасовский'
               },
               {
                   name: 'Принято в отделении связи',
                   txt: '25 мая 2022, 12:22 141865, Некрасовский'
               },
               {
                   name: 'Присвоен трек-номер',
                   txt: '24 мая 2022, 22:41 141733, Лобня'
               },

           ]
        }
    }
}
</script>

<style scoped>
    input {
        margin-top: 0;
        margin-bottom: 20px;
        background-color: #ECECEC;
        border-color: #ECECEC;
        color: #000;
        padding: 10px;
    }
    label {
        color: #5c5c5c;
    }
</style>