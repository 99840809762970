<template>
  <section id="main">
    <div class="container outscale">
      <div class="row">
        <div class="col-lg-6">
          <h1
            class="wow fadeInUp"
            v-if="lang === 'ru'"
          >
            Все грани нашей <span class="orange">логистики</span>
          </h1>
          <h1
            class="wow fadeInUp"
            v-else
          >
            All facets of our <span class="orange">logistics</span>
          </h1>
          <p
            class="big-white wow fadeIn"
            data-wow-delay=".4s"
          >
            {{ lang === 'ru' ? 'Эффективная консолидация сборных грузов и доставка от 1 кг в любом международном направлении.' : 'Effective consolidation of combined cargoes and delivery from 1 kg in any international direction.' }}
          </p>
        </div>
        <div class="col-lg-6 d-none d-md-block">
          <div
            class="tnved-box wow fadeIn"
            data-wow-delay=".8s"
          >
            <h3> {{ lang === 'ru' ? 'Отслеживание груза' : 'Cargo tracking' }} </h3>
            <div class="row">
              <div class="col-lg-12">
                <label for="">{{ lang === 'ru' ? 'Введите номер отправления' : 'Enter departure number' }}</label>
              </div>
              <div class="col-lg-9">
                <input
                  type="text"
                  placeholder="980233453216"
                  v-model="search"
                >
              </div>
              <div class="col-lg-3">
                <button
                  class="main-btn"
                  style="width:100%;"
                >
                  {{ lang === 'ru' ? 'Поиск' : 'Search' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="scroll-btn">
                <img src="../assets/img/scroll.svg" alt="">
                <p class="white-txt">Скрольте вниз</p>
              </div> -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
const basicScroll = require('basicscroll')

export default {
    data(){
      return{
        search: ''
      }
    },
    computed: {
      ...mapGetters({
        lang: "lang/getLang"
      })
    },
    methods: {
      goToTnved(){
        if(this.search !== null && this.search.length >= 3){
          this.$store.dispatch('serv/setGlobalSearch', this.search)
          this.$router.replace('/uslugi/poisk-po-tnved')
        }
      }
    },
    mounted(){
        const instance1 = basicScroll.create({
				elem: document.querySelector('#main'),
				from: 'top-top',
				to: 'middle-top',
				props: {
					'--opacity': {
						from: 1,
						to: 0
					},
                    '--scale': {
                        from: 1,
                        to: 1.06
                    },
				}
			})
	    instance1.start()
  }
}
</script>