import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Serv from '../views/Serv.vue'
import Test from '../views/Test.vue'
import ArticleSearch from '../views/ArticleSearch.vue'
import Examples from '../views/Examples.vue'
import Cargo from '../views/Cargo.vue'
import Russia from '../views/Russia.vue'
import Tnved from '../views/Tnved.vue'
import TnvedRazdel from '../views/TnvedRazdel.vue'
import TnvedInside from '../views/TnvedInside.vue'
import TnvedCode from '../views/TnvedCode.vue'
import Tracking from '../views/Tracking.vue'
import IndexCalc from '../views/IndexCalc.vue'
import Sales from '../views/Sales.vue'
import SingleSale from '../views/SingleSale.vue'
// 
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import Profile from '../views/auth/Profile.vue'

import MainInfo from '../views/auth/MainInfo.vue'
import Stat from '../views/auth/Stat.vue'
import Logistics from '../views/auth/Logistics.vue'
import SingleOrder from '../views/auth/SingleOrder.vue'
import PwaPage from '../views/PwaPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/pwa',
    component: PwaPage,
    name: 'Pwa'
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/uslugi',
    component: Serv
  },
  {
    path: '/uslugi/tnved',
    component: IndexCalc
  },
  {
    path: '/uslugi/poisk-po-artikulu',
    component: ArticleSearch
  },
  {
    path: '/uslugi/examples',
    component: Examples,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/getAuthenticated"]) {
        next()
      } else {
        if (to.path != "/enter") {
          next("/enter")
        }
        else {
          next()
        }
      }
    }
  },
  {
    path: '/uslugi/mezhdunarodnaya',
    component: Cargo
  },
  {
    path: '/uslugi/po-rf',
    component: Russia
  },
  {
    path: '/uslugi/poisk-po-tnved',
    component: Tnved,
    children: [
      {
        path: '/uslugi/poisk-po-tnved',
        component: TnvedRazdel
      },
      {
        path: '/uslugi/poisk-po-tnved/code/:id',
        component: TnvedCode,
        props: true
      },
      {
        path: '/uslugi/poisk-po-tnved/:id',
        component: TnvedInside,
        props: true
      }
    ]
  },
  {
    path: '/uslugi/otslezhivanie',
    component: Tracking
  },
  {
    path: '/uslugi/:id',
    component: Test,
    props: true
  },
  {
    path: '/sales',
    component: Sales
  },
  {
    path: '/sales/:id',
    component: SingleSale
  },
  // auth
  {
    path: '/enter',
    component: Login
  },
  {
    path: '/registration',
    component: Signup
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/getAuthenticated"]) {
        next()
      } else {
        if (to.path != "/enter") {
          next("/enter")
        }
        else {
          next()
        }
      }
    },
    children: [
      {
        component: MainInfo,
        path: '/',
        beforeEnter: (to, from, next) => {
      if (store.getters["auth/getAuthenticated"]) {
        next()
      } else {
        if (to.path != "/enter") {
          next("/enter")
        }
        else {
          next()
        }
      }
    }
      },
      {
        component: SingleOrder,
        path: '/profile/order/:id',
        props: true,
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/getAuthenticated"]) {
            next()
          } else {
            if (to.path != "/enter") {
              next("/enter")
            }
            else {
              next()
            }
          }
        }
      },
      {
        component: Stat,
        path: '/profile/stat',
        beforeEnter: (to, from, next) => {
      if (store.getters["auth/getAuthenticated"]) {
        next()
      } else {
        if (to.path != "/enter") {
          next("/enter")
        }
        else {
          next()
        }
      }
    }
      },
      {
        component: Logistics,
        path: '/profile/logistics',
        beforeEnter: (to, from, next) => {
      if (store.getters["auth/getAuthenticated"]) {
        next()
      } else {
        if (to.path != "/enter") {
          next("/enter")
        }
        else {
          next()
        }
      }
    }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
