const sales = {
	namespaced: true,
	state: {
        sales: [
            {
                name: 'Название акции/предложения',
                txt: 'Описание краткое содержание с <span>выделенными цифрами</span>',
                col: 4
            },
            {
                name: 'Название акции/предложения',
                txt: 'Описание краткое содержание с <span>выделенными цифрами</span>',
                col: 4
            },
            {
                name: 'Название акции/предложения',
                txt: 'Описание краткое содержание с <span>выделенными цифрами</span>',
                col: 4
            },
            {
                name: 'Акция',
                txt: 'Описание с <span> цифрами</span>',
                col: 4
            },
            {
                name: 'Название акции/предложения',
                txt: 'Описание краткое содержание с <span>выделенными цифрами</span>',
                col: 4
            },
            {
                name: 'Акция',
                txt: 'Описание с <span> цифрами</span>',
                col: 4
            },
        ],
  	},
	mutations: {
	},
	actions: {

	},
	getters: {
        getSales(state){
            return state.sales
        }
	}
}

export default sales


