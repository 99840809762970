const lang = {
	namespaced: true,
	state: {
                lang: 'ru'
  	},
	mutations: {
                SET_LANG(state, lang){
                state.lang = lang
                }
	},
	actions: {
                changeLang({commit}, lang){
                commit("SET_LANG", lang)
                }
	},
	getters: {
                getLang(state){
                return state.lang
                }
	}
}

export default lang


