<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                Главная <span class="mdi mdi-chevron-right" />
              </router-link>
              <router-link
                tag="a"
                to="/uslugi"
              >
                Услуги <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>Поиск по  ТН ВЭД</span>
            </div>
            <h2>Поиск по  ТН ВЭД</h2>
          </div>
        </div>
      </div>
    </section>

    <router-view />

    <!-- <section id="usual">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tnved-grey-box">
                            <div class="razdel mb-30" v-for="(item, index) in tnved" :key="index">
                                <pre @click="openRazdel(item.ID)">{{item}}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

    <!-- <section id="usual">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb-30">
                        <img class="icon1 mb-30">
                        <form @submit.prevent="goSearch" class="order-form">
                            <div class="row">
                                <div class="col-lg-9">
                                    <input v-model="searchBox" type="text" placeholder="Введите код или наименование">
                                </div>
                                <div class="col-lg-3">
                                    <button type="submit" class="main-btn" style="width:100%;">Поиск</button>
                                </div>
                            </div>
                        </form>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tnved-grey-box" v-if="tnved.length">
                            <v-treeview :items="tnved" item-key="id"
                                            open-on-click
                                            ref="tnved"
                                            :open.sync="opened"
                                            class="piz123">
                                <template v-slot:prepend="{ item }" class="">
                                    <div class="tnved-row" v-if="firstChilds.includes(item.id)" 
                                    :id="`r${item.id}`" @click.stop>
                                        <h3>РАЗДЕЛ {{item.section}}. {{item.description}}</h3>
                                    </div>
                                    <div class="tnved-row" v-else-if="item.code.length == 2" :id="`r${item.id}`">
                                        <div class="razdel-code">{{item.code}}</div>
                                        <h4 class="razdel">{{item.description}}</h4>
                                    </div>
                                    <div class="tnved-row" v-else :id="`r${item.id}`">
                                        
                                        <div class="orange-minus" v-if="item.code === ''" @click.stop></div>
                                        <h4 @click.stop @click="loadSingleCode(item.code)" v-else>
                                            <text-highlight :queries="newHigh">{{item.code}}</text-highlight>
                                        </h4>
                                        <p class="black-txt">
                                            <text-highlight :queries="newHigh">{{item.description}}</text-highlight>
                                        </p>
                                    </div>
                                </template> 
                            </v-treeview>
                        </div>
                    </div>
                </div>
            </div>
        </section>  -->
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'

export default {
    methods: {
        openRazdel(id, item){
            let newNulls = '0'.repeat(8 - id.toString().length)
            console.log(newNulls + id)
            axios
            .get(`https://api1.tks.ru/tree.json/json/f0a574d9805d417c88045fb5e478017f/${newNulls + id}.json`)
            .then(res =>{
                this.tnved = res.data
            })
        },
        // 
        normalizeString(s){
            // удаление пробелов кода
                let prevS = ''; 
                while(prevS !== s){
                    prevS = s; s = s.replace(/([0-9]+) ([0-9]+)/, '$1$2');
                }
                return s
        },
        loadSingleCode(code){
            this.$store.dispatch('serv/loadSingleCode', code)
        },
        goSearch(){
            this.searchBox = this.normalizeString(this.searchBox)

            var num = this.searchBox.match(/\d+/g);
            var letr = this.searchBox.match(/[а-яA-Я]+/g);

            this.exmplCode = ''
            this.exmplParam = ''


            if(num != null && letr == null){
                this.url = `https://declarant.by/proxy.php?url=read/nsiTnVedsJoinedByCodeAndDescription?code=${num.join('')}&unwrap=1`
            }else if(letr != null && num == null){
                this.url = `https://declarant.by/proxy.php?url=read/nsiTnVedsJoinedByCodeAndDescription?&description=${letr.join(' ')}&unwrap=1`
            }else{
                this.url = `https://declarant.by/proxy.php?url=read/nsiTnVedsJoinedByCodeAndDescription?code=${num[0]}&description=${letr.join(' ')}&unwrap=1`
            }

             // запрос поиска по тнвэд
            axios
            .get(this.url)
            .then(res =>{
                let ids = [1,2008,2972,3243,5082,5512,7499,8079,8412,9029,9377,11249,11447,11949,12080,14000,17221,18617,19184,19222,19711,6799]

                // if(!res.data.tnVeds.length){
                //     this.preloader = false
                //     this.searchErr = 'К сожалению ничего не найдено, попробуйте еще раз.'
                //     return
                // }


               const vm = this

                function parseObjectKeys(obj) {
                    
                    for (var prop in obj) {
                        var sub = obj[prop]
                        if (prop=="id") {
                            ids.push(obj[prop])
                        }
                        if (prop=="highlights") {
                            // console.log(obj[prop])
                            
                            if(obj[prop].length){
                                obj[prop].forEach(item =>{
                                    vm.newHigh.push(item)
                                })
                            }
                        }
                        if (typeof(sub) == "object") {
                            parseObjectKeys(sub);
                        }
                    }
                }
                
                parseObjectKeys(res.data.tnVeds);
                let uniqueHigh = new Set(this.newHigh)

                this.newHigh = [...uniqueHigh]
                
                this.opened = ids
                this.highliten = ids

                


                this.searchIds = res.data.highlightIds




                setTimeout(()=>{
                    // let hlItem = document.querySelectorAll('.text__highlight');
                    this.searchErr = ''
                    // this.hgLength = this.searchIds.length
                    // if(this.searchIds.length){
                    //     this.hgActive = 1
                    // }
                    
                    // let options = {offset: -250,force: true} // 260
                    // this.$scrollTo(`#r${this.searchIds[0]}` , 200, options)
                    // this.preloader = false

                    // let hlRow = document.querySelectorAll('.hlRow')

                    // hlRow[this.hgActive - 1].classList.add('yellRow')


                    
                }, 120)
                this.$store.dispatch('serv/clearGlobal')
            })  
        }
    },
    data() {
       return {
           tnved: [] 
       }
    },
    created(){
        axios
        .get('https://api1.tks.ru/tree.json/json/f0a574d9805d417c88045fb5e478017f/00000010.json')
        .then(res =>{
            this.tnved = res.data
        })
    }
}
</script>

<style scoped>
.home::before{
    display: none;
}
</style>