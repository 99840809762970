<template>
  <header :class="[{glassHeader : glassHeader}, {'headroom-unpinned': scrolled}]">
    <div class="container">
      <div class="shapka">
        <router-link
          tag="a"
          to="/"
        >
          <img
            src="../../assets/img/logo.svg"
            class="logo"
          >
        </router-link>
        <div class="header-nav d-none d-md-flex">
          <ul>
            <li
              @click="scrollToDiv"
              v-if="this.$route.name === 'Home'"
            >
              <span class="mdi mdi-pencil" />{{ lang === 'ru' ? 'Контакты' : 'Contacts' }}
            </li>
            <router-link
              tag="li"
              to="/#mapbox"
              v-else
            >
              <span class="mdi mdi-pencil" />{{ lang === 'ru' ? 'Контакты' : 'Contacts' }}
            </router-link>
            <router-link
              tag="li"
              to="/profile"
            >
              <span class="mdi mdi-account" />{{ lang === 'ru' ? 'Личный кабинет' : 'Personal account' }}
            </router-link>
            <router-link
              tag="li"
              to="/pwa"
            >
              <span class="mdi mdi-cellphone-play" />{{ lang === 'ru' ? 'Мобильное приложение' : 'Mobile application' }}
            </router-link>
            <li
              id="myButton"
              @click="changeLang"
              class="text-uppercase"
            >
              <span class="mdi mdi-web" /> {{ lang }}
            </li>
          </ul>
          <div
            class="burger"
            @click="showDrop = !showDrop"
          >
            <span :class="{'orange-bg' : showDrop}" />
            <span :class="{'orange-bg' : showDrop}" />
            <span :class="{'orange-bg' : showDrop}" />
          </div>
        </div>
        <div class="header-nav d-flex d-md-none">
          <ul class="d-flex">
            <router-link
              tag="li"
              to="/profile"
            >
              <span class="mdi mdi-account" />
            </router-link>
            <li
              id="myButton"
              @click="changeLang"
              class="text-uppercase"
            >
              <span class="mdi mdi-web" />
            </li>
          </ul>
        </div>
        <div
          class="burger d-md-none"
          @click="showDropMob = !showDropMob"
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <DropMenu
        v-if="showDrop"
        @closeDrop="showDrop = false"
      />
      <DropMenuMob
        v-if="showDropMob"
        @closeDrop="showDropMob = false"
      />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import DropMenu from './DropMenu.vue'
import DropMenuMob from './DropMenuMob.vue'
export default {
    components: {DropMenu, DropMenuMob},
    data(){
        return{
            showDrop: false,
            showDropMob: false,
            limitPosition: 300,
            scrolled: false,
            lastPosition: 0
        }
    },
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
    props:{
        glassHeader: {
            required: true,
            type: Boolean
        }
    },
    methods: {
        scrollToDiv(){
          //  document.getElementById('mapbox').scrollIntoView({ behavior: 'smooth', block: 'center' })
          $('html, body').animate({scrollTop: $('#mapbox').offset().top + 200 }, 'slow')
        },
        goScroll(){
            this.$nextTick(() => {
                // let options = {
                // offset: 140,
                // force: true
                // }
                // this.$scrollTo('#mapbox', 200, options)
                $('html, body').animate({scrollTop: $('#mapbox').offset().top + 200 }, 'slow')
            })
        },
        changeLang(){
            const payload = this.lang === 'ru' ? 'en' : 'ru'
            this.$store.dispatch("lang/changeLang", payload).then(() =>{
                tippy('#myButton', {
                    content: `${this.lang === 'ru' ? 'Сменить язык на En' : 'Switch language to RU'}`,
                });
            })
        },
        handleScroll() {
            this.showDrop = false
            if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                this.scrolled = true;
                // move up!
            } 
            if (this.lastPosition > window.scrollY) {
                this.scrolled = false;
                // move down
            }
            this.lastPosition = window.scrollY;
            // this.scrolled = window.scrollY > 250;
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        tippy('#myButton', {
            content: `${this.lang === 'ru' ? 'Сменить язык на En' : 'Switch language to RU'}`,
        });
    }
}
</script>