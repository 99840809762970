<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h2>{{ lang === 'ru' ? 'Личный кабинет' : 'Personal account' }}</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="login">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 offset-lg-4 text-center">
            <h3 class="black">
              {{ lang === 'ru' ? 'Войти' : 'Login' }}
            </h3>
            <form @submit.prevent="login">
              <input
                type="text"
                :placeholder="lang === 'ru' ? 'Логин' : 'Login'"
                class="mb-15"
                v-model="name"
              >
              <div class="pass">
                <input
                  :type="passvis ? 'text' : 'password'"
                  :placeholder="lang === 'ru' ? 'Пароль' : 'Password'"
                  class="mb-15"
                  v-model="pass"
                >
                <span
                  class="mdi"
                  @click="passvis = !passvis"
                  :class="passvis ? 'mdi-eye-off' : 'mdi-eye'"
                />
              </div>
              <button
                type="submit"
                class="main-btn w-100 mb-15"
              >
                {{ lang === 'ru' ? 'Войти' : 'Login' }}
              </button>
            </form>
            <p class="black-txt">
              Еще нет аккаунта? <br><router-link
                tag="a"
                class="orange"
                to="/registration"
              >
                Зарегистрируйтесь
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            passvis: false,
            name: '',
            pass: ''
        }
    },
    methods: {
        login(){
            let form = {
                username: this.name,
                password: this.pass,
            };

            this.$store.dispatch('auth/AUTH_REQUEST', form).then((err) => {
                    this.load = false
                    if(err){
                        return alert(err)
                    }else{
                        this.$router.replace("/profile");
                    }
            });
        }
    },
    computed: {
        ...mapGetters({
            lang: "lang/getLang"
        })
    },
}
</script>

<style scoped>
.home::before {
    display: none ;
}
.pass{
    position: relative;
}
.pass .mdi{
    position: absolute;
    top: 15px;
    color: #919191;
    cursor: pointer;
    font-size: 20px;
    right: 20px;
}
</style>