<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ lang === 'ru' ? 'О компании' : 'About the company' }}</span>
            </div>
            <h2>{{ lang === 'ru' ? 'О компании' : 'About the company' }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="usual">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-30" />
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="adv mb-30">
              <h3>{{ current_year - 2015 }}</h3>
              <h4 v-if="lang === 'ru'">
                ЛЕТ НА РЫНКЕ
              </h4>
              <h4 v-else>
                YEARS ON THE MARKET
              </h4>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="adv mb-30">
              <h3>500+</h3>
              <h4>{{ lang === 'ru' ? 'ДЕКЛАРАЦИЙ' : 'DECLARATIONS' }}</h4>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="adv mb-30">
              <h3>1000</h3>
              <h4>{{ lang === 'ru' ? 'ДОСТАВЛЕНО ГРУЗОВ' : 'LOADS DELIVERED' }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="usual"
      style="background-color:#ECECEC;"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="dark-h3">
              {{ lang === 'ru' ? 'Наши преимущества' : 'Our advantages' }}
            </h3>
          </div>
          <div class="col-lg-3">
            <div class="icon-box">
              <img
                src="../assets/img/icons/ic5.svg"
                alt=""
              >
              <p class="big-black">
                {{ lang === 'ru' ? 'База примеров декларирования' : 'Database of declaration examples' }}
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="icon-box">
              <img
                src="../assets/img/icons/ic2.svg"
                alt=""
              >
              <p
                class="big-black"
                v-html="lang === 'ru' ? 'Поиск товара по <br> артикулу' : 'Product search <br> by article'"
              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="icon-box">
              <img
                src="../assets/img/icons/ic9.svg"
                alt=""
              >
              <p class="big-black">
                {{ lang === 'ru' ? 'Система отслеживания движения груза' : 'Cargo tracking system' }}
              </p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="icon-box">
              <img
                src="../assets/img/icons/ic10.svg"
                alt=""
              >
              <p class="big-black">
                {{ lang === 'ru' ? 'Собственные IT-решения для оптимизации процессов' : 'Own IT solutions for process optimization' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section
      id="usual"
      v-if="about"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-30">
            <h2
              class="dark-h3 text-center"
              v-if="lang === 'ru'"
            >
              Статистика перевезенных грузов
            </h2>
            <h2
              class="dark-h3 text-center"
              v-else
            >
              Transported cargo statistics
            </h2>
          </div>
          <div class="col-lg-12 mb-75">
            <h3
              class="dark-h3 text-center"
              v-if="lang === 'ru'"
            >
              Импорт:
            </h3>
            <h3
              class="dark-h3 text-center"
              v-else
            >
              Import:
            </h3>
            <ul class="stat-progress">
              <li
                v-for="(item, i) in about.blocks.import"
                :key="i"
              >
                <p class="black-txt">
                  {{ item.nazvanie }}
                </p>
                <div class="bar">
                  <span>{{ item['kol-vo'] }}</span>
                  <div
                    class="inbar"
                    :style="`width:${getWidth(item['kol-vo'], about.blocks.import)}%;`"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-12">
            <h3
              class="dark-h3 text-center"
              v-if="lang === 'ru'"
            >
              Экспорт:
            </h3>
            <h3
              class="dark-h3 text-center"
              v-else
            >
              Export:
            </h3>
            <ul class="stat-progress">
              <li
                v-for="(item, i) in about.blocks.export"
                :key="i"
              >
                <p class="black-txt">
                  {{ item.nazvanie }}
                </p>
                <div class="bar">
                  <span>{{ item['kol-vo'] }}</span>
                  <div
                    class="inbar"
                    :style="`width:${getWidth(item['kol-vo'], about.blocks.export)}%;`"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    

    <section id="order">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="dark-h3 mb-10">
              {{ lang === 'ru' ? 'Заказать услугу' : 'Order service' }}
            </h3>
            <p class="black-txt mb-30">
              {{ lang === 'ru' ? 'Наши менеджеры быстро ответят на заявку' : 'Our managers will quickly respond to the application' }}
            </p>
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <form
                  @submit.prevent="submitForm"
                  class="order-form"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'ФИО' : 'Name'"
                        v-model="fio"
                        :class="{errorInp :$v.fio.$dirty && !$v.fio.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'Телефон' : 'Phone'"
                        v-model="phone"
                        :class="{errorInp :$v.phone.$dirty && !$v.phone.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        placeholder="E-mail"
                        v-model="email" 
                        :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
                      >
                    </div>
                    <div class="col-lg-12">
                      <input
                        type="text"
                        :placeholder="`${lang === 'ru' ? 'Услуга' : 'Service'}`"
                        v-model="service"
                      >
                    </div>
                    <div class="col-lg-12">
                      <textarea
                        :placeholder="lang === 'ru' ? 'Комментарий' : 'Comment'"
                        rows="5"
                        v-model="comment"
                      />
                    </div>
                    <div class="col-lg-12 text-center">
                      <p
                        class="small-gray"
                        v-if="lang === 'ru'"
                      >
                        Нажимая "Отправить", вы соглашаетесь с  <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                          class="orange"
                        >Политикой конфиденциальности сайта</a>
                      </p>
                      <p
                        class="small-gray"
                        v-else
                      >
                        By clicking "Submit", you agree with the <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/website-privacy-policy-and-personal-data-processing-interval.pdf"
                          class="orange"
                        >Website Privacy Policy</a>
                      </p>
                      <br>
                      <loader v-if="loading" />
                      <button
                        class="main-btn"
                        v-else
                      >
                        {{ lang === 'ru' ? 'Отправить' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr class="mt-40">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formGlobal from '@/mixins/formGlobal'

export default {
    mixins: [formGlobal],
    computed: {
        ...mapGetters({
            lang: "lang/getLang",
            about: "serv/getAbout"
        })
    },
    methods: {
        getWidth(num, arr){
            const hundred = arr[0]['kol-vo']
            return 100/hundred * parseInt(num)
        }
    },
    data(){
       return{
        current_year: new Date().getFullYear(),
       }
   }
}
</script>

<style scoped>
.home::before {
    display: none;
}
.ymap-container{
    height: 550px;
}
</style>