<template>
  <section id="services">
    <div class="container">
      <div class="white-box">
        <div class="row">
          <div class="col-lg-12">
            <h2>{{ lang === 'ru' ? 'Наши продукты' : 'Our products' }}</h2>
          </div>
          <div
            class="wow fadeInUp"
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in services"
            :key="i"
          >
            <router-link
              tag="div"
              :to="`/uslugi/${item.slug}`"
              class="serv-box"
            >
              <img
                :src="item.ic1"
                class="icon1"
              >
              <img
                :src="item.ic2"
                class="icon2"
              >
              <h4>{{ item.name[lang] }}</h4>
              <p class="black-txt">
                {{ item.txt }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
          lang: "lang/getLang",
          services: "serv/getServ"
        })
    }
}
</script>