import axios from 'axios'
import { required, email } from "vuelidate/lib/validators";
import loader from '@/components/ui/MiniLoader.vue'

export default {
    components: {loader},
    data(){
        return{
            loading: false,
            fio: '',
            phone: '',
            email: '',
            position: '',
            comment: ''
        }
    },
    validations: {
        fio: {required},
        phone: {required},
        email: {required, email}
    },
    methods: {
        submitForm(){
            if(this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.loading = true
            const emailBody = {
				fio: this.fio,
                phone: this.phone,
                email: this.email,
                position: this.position,
                comment: this.comment
            }

			const form = new FormData();

			for (var field in emailBody){
				form.append(field, emailBody[field]);
			};

            axios.post('https://new.interval.spb.ru/wp-json/contact-form-7/v1/contact-forms/4101/feedback', form)
            .then(() => {
                this.loading = false
                this.fio = ''
                this.phone = ''
                this.email = ''
                this.position = ''
                this.comment = ''
                this.$v.$reset()
                alert('Заявка отправлена')
            })
            .catch(() => {
                alert('Ошибка, попробуйте позже')
            })
        }
    }
}