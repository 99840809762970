<template>
  <div class="home">
    <section id="inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrums">
              <router-link
                tag="a"
                to="/"
              >
                {{ lang === 'ru' ? 'Главная' : 'Home' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <router-link
                tag="a"
                to="/uslugi"
              >
                {{ lang === 'ru' ? 'Услуги' : 'Services' }} <span class="mdi mdi-chevron-right" />
              </router-link>
              <span>{{ lang === 'ru' ? 'Поиск по ТН ВЭД' : 'Search by the Customs Code of the EAEU (HS)' }}</span>
            </div>
            <h2>{{ lang === 'ru' ? 'Поиск по ТН ВЭД' : 'Search by the Customs Code of the EAEU (HS)' }}</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="usual">
      <div id="ccs-app" />
    </section>
    <section id="order">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="dark-h3 mb-10">
              {{ lang === 'ru' ? 'Заказать услугу' : 'Order service' }}
            </h3>
            <p class="black-txt mb-30">
              {{ lang === 'ru' ? 'Наши менеджеры быстро ответят на заявку' : 'Our managers will quickly respond to the application' }}
            </p>
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <form
                  @submit.prevent="submitForm"
                  class="order-form"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'ФИО' : 'Name'"
                        v-model="fio"
                        :class="{errorInp :$v.fio.$dirty && !$v.fio.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        :placeholder="lang === 'ru' ? 'Телефон' : 'Phone'"
                        v-model="phone"
                        :class="{errorInp :$v.phone.$dirty && !$v.phone.required}"
                      >
                    </div>
                    <div class="col-lg-4">
                      <input
                        type="text"
                        placeholder="E-mail"
                        v-model="email" 
                        :class="{errorInp : ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
                      >
                    </div>
                    <div class="col-lg-12">
                      <input
                        type="text"
                        :value="getServVal(lang === 'ru' ? 'Услуга: Доставка и таможенное оформление' : 'Service: Delivery and customs clearance')"
                        disabled
                      >
                    </div>
                    <div class="col-lg-12">
                      <textarea
                        :placeholder="lang === 'ru' ? 'Комментарий' : 'Comment'"
                        rows="5"
                        v-model="comment"
                      />
                    </div>
                    <div class="col-lg-12 text-center">
                      <p
                        class="small-gray"
                        v-if="lang === 'ru'"
                      >
                        Нажимая "Отправить", вы соглашаетесь с  <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/politika-konfidenczialnosti-sajta-interval.pdf"
                          class="orange"
                        >Политикой конфиденциальности сайта</a>
                      </p>
                      <p
                        class="small-gray"
                        v-else
                      >
                        By clicking "Submit", you agree with the <a
                          href="https://new.interval.spb.ru/wp-content/uploads/2023/07/website-privacy-policy-and-personal-data-processing-interval.pdf"
                          class="orange"
                        >Website Privacy Policy</a>
                      </p>
                      <br>
                      <loader v-if="loading" />
                      <button
                        class="main-btn"
                        v-else
                      >
                        {{ lang === 'ru' ? 'Отправить' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="addition">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="dark-h3 mb-30">
              {{ lang === 'ru' ? 'Дополнительные услуги' : 'Additional services' }}
            </h3>
          </div>
          <div
            :class="`col-lg-${item.col}`"
            v-for="(item, i) in services"
            :key="i"
          >
            <router-link
              tag="div"
              :to="`/uslugi/${item.slug}`"
              class="serv-box"
            >
              <img
                :src="item.ic1"
                class="icon1"
              >
              <img
                :src="item.ic2"
                class="icon2"
              >
              <h4>{{ item.name[lang] }}</h4>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formGlobal from '@/mixins/formGlobal'

export default {
    mixins: [formGlobal],
  computed: {
        ...mapGetters({
            lang: "lang/getLang",
        })
    },
    data(){
      return {
        services: [
                {
                    slug: 'sertifikaciya',
                    name: {en: 'Permissive documentation', ru: 'Разрешительная документация'},
                    ic1: require('../assets/img/icons/ic6.svg'),
                    ic2: require('../assets/img/icons/ic6w.svg'),
                    col: 4
                },
                {
                    slug: 'mezhdunarodnaya',
                    name: {en: 'International transportation', ru: 'Международная перевозка'},
                    ic1: require('../assets/img/icons/ic7.svg'),
                    ic2: require('../assets/img/icons/ic7w.svg'),
                    col: 4
                },
                {
                    slug: 'tamozj',
                    name: {en: 'Customs clearance', ru: 'Таможенное оформление'},
                    ic1: require('../assets/img/icons/ic1.svg'),
                    ic2: require('../assets/img/icons/ic1w.svg'),
                    col: 4
                },
           ]
      }
    },
  mounted(){
    setTimeout(() => {
      let tnvedScript = document.createElement('script')
      tnvedScript.setAttribute('src', 'https://new.interval.spb.ru/tks/tnved.dev.js')
      document.body.appendChild(tnvedScript)
    }, 200);
  }

}
</script>

<style scoped>
.home::before {
    display: none;
}
</style>
