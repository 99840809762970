<template>
  <div class="home">
    <section id="login">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 offset-lg-12 text-center">
            <div class="os-tab">
                <h3 
                    class="black mb-4" v-for="(tab, i) in tabs" 
                    :key="i" 
                    :class="{ 'active' : selected_os === tab.name }" 
                    @click="selected_os = tab.name"
                >
                    <span class="mdi" :class="`mdi-${tab.icon}`"></span>
                    {{tab.name}}
                </h3>
            </div>
            <p class="black-txt" v-html="selected_explain">
                Установите веб-приложение на iPhone: <br>
                Нажмите <span class="mdi mdi-tray-arrow-up mx-1 orange"></span> внизу Safari и выберите 'На экран «Домой»'
            </p>
            
            <!-- <p class="black-txt">
              Еще нет аккаунта? <br><router-link
                tag="a"
                class="orange"
                to="/registration"
              >
                Зарегистрируйтесь
              </router-link>
            </p> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
    data(){
        return {
            tabs: [
                {
                    name: 'IOS', 
                    icon: 'apple',
                    explain: 'Установите веб-приложение на iPhone: <br> Нажмите <span class="mdi mdi-tray-arrow-up mx-1 orange"></span> внизу Safari и выберите - На экран «Домой»'
                },
                {
                    name: 'Android', 
                    icon: 'android',
                    explain: 'Установите веб-приложение на Android: <br> Нажмите <span class="mdi mdi-dots-vertical mx-1 orange"></span> в правом верхнем углу браузера <br> и выберите - «Домашний экран»'
                }
            ],
            selected_os: 'IOS'
        }
    },
    computed: {
        selected_explain(){
            return this.tabs.find((item) => item.name === this.selected_os).explain
        }
    }  
}
</script>

<style scoped>
.home::before {
    display: none ;
}
#login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.black-txt .mdi{
    font-size: 22px;
    transform: translateY(2px);
    display: inline-block;
}
.os-tab{
    display: flex;
    justify-content: center;
    align-items: center;
}
.os-tab h3{
    padding: 6px 24px;
    cursor: pointer;
    border-bottom: 2px #fff solid;
}
.os-tab h3.active{
    border-bottom: 2px #EA6530 solid;
}
</style>